import { ISeasonPrices } from 'pages/Quote/Quote';
import { createSelector } from 'reselect';
import { IShortBreakArrivalDistribution, IUpstreamProperty } from 'services/appDatabase';
import { getCompleteYearSummary, getYearSummary } from 'services/engine/novasol/pricing';
import {
  IAveragePriceChangeResult,
  IMarkupResult,
  IOccupancyResult,
  IPricingResult,
  IRange,
  IRevenueResult,
  IShortBreakResult,
  IStayTypeValue,
  IWeekPricingData,
  IWeekStayTypeBreakdown,
} from 'services/engine/types';
import { applyFloorCap } from 'services/utils/numberUtils';
import { RootState } from 'stateManagement/types';
import { AdjustedResult, AdjustedResultItem } from './reducer';

export interface IStayTypeRange {
  type: string;
  nights: number;
  range: IRange;
}

export const getProperties = (state: RootState): IUpstreamProperty[] => state.engine.properties;
export const getOccupancyResults = (state: RootState): AdjustedResult<IOccupancyResult[]> =>
  state.engine.occupancyResults;

export const getBDMOccupancy = createSelector(
  getOccupancyResults,
  (
    occupancyResult: AdjustedResult<IOccupancyResult[]>,
  ): AdjustedResultItem<IOccupancyResult[]> | null => {
    if (occupancyResult.bdm && occupancyResult.bdm.reason !== '') {
      return occupancyResult.bdm;
    } else if (occupancyResult.engine && occupancyResult.engine.data.length > 0) {
      return occupancyResult.engine;
    } else {
      return null;
    }
  },
);

export const getLatestOccupancy = createSelector(
  getOccupancyResults,
  getBDMOccupancy,
  (
    occupancyResult: AdjustedResult<IOccupancyResult[]>,
    bdmOccupancyResult: AdjustedResultItem<IOccupancyResult[]> | null,
  ): AdjustedResultItem<IOccupancyResult[]> | null => {
    if (occupancyResult.owner && occupancyResult.owner.reason !== '') {
      return occupancyResult.owner;
    } else {
      return bdmOccupancyResult;
    }
  },
);

export const getPricingResults = (state: RootState): AdjustedResult<IPricingResult[]> =>
  state.engine.pricingResults;

export const getSeasonPrices = (state: RootState): ISeasonPrices => state.engine.newSeasonPrices;

export const getMarkupResults = (state: RootState): AdjustedResult<IMarkupResult> =>
  state.engine.markupResults;

export const getLatestMarkupResults = createSelector(
  getMarkupResults,
  (markupResults: AdjustedResult<IMarkupResult>): IMarkupResult => {
    if (markupResults.owner && markupResults.owner.reason !== '') {
      return markupResults.owner.data;
    } else if (markupResults.bdm && markupResults.bdm.reason !== '') {
      return markupResults.bdm.data;
    }
    return markupResults.engine.data;
  },
);

export const getFloorPrice = (state: RootState): number | null => state.engine.floorPrice;

export const getCalendarId = (state: RootState): string => state.quote.calendar;

export const getEnginePricing = createSelector(
  getPricingResults,
  (pricingResult: AdjustedResult<IPricingResult[]>): AdjustedResultItem<IPricingResult[]> => {
    return pricingResult.engine;
  },
);

export const getAdjustedPricing = createSelector(
  getPricingResults,
  (
    pricingResult: AdjustedResult<IPricingResult[]>,
  ): AdjustedResultItem<IPricingResult[]> | null => {
    if (pricingResult.adjusted && pricingResult.adjusted.data.length > 0) {
      return pricingResult.adjusted;
    }
    if (pricingResult.owner && pricingResult.owner.data.length > 0) {
      return pricingResult.owner;
    }
    if (pricingResult.bdm && pricingResult.bdm.data.length > 0) {
      return pricingResult.bdm;
    }
    if (pricingResult.engine && pricingResult.engine.data.length > 0) {
      return pricingResult.engine;
    }
    return null;
  },
);

export const getLatestPricing = createSelector(
  getAdjustedPricing,
  (
    adjustedPricingResult: AdjustedResultItem<IPricingResult[]> | null,
  ): AdjustedResultItem<IPricingResult[]> | null => {
    return adjustedPricingResult;
  },
);

export const getLatestPricingWithFloorPrice = createSelector(
  getLatestPricing,
  getFloorPrice,
  (
    latestPricing: AdjustedResultItem<IPricingResult[]> | null,
    floorPrice: number | null,
  ): AdjustedResultItem<IPricingResult[]> | null => {
    if (!latestPricing) {
      return null;
    }
    if (!floorPrice) {
      return latestPricing;
    }
    return {
      data: latestPricing.data.map((el) => ({
        ...el,
        yearData: el.yearData.map((p) => ({ ...p, price: applyFloorCap(p.price, floorPrice) })),
      })),
    };
  },
);

export const getPricingResultsYearOne = createSelector(
  getLatestPricingWithFloorPrice,
  (latestPricing: AdjustedResultItem<IPricingResult[]> | null): IWeekPricingData[] => {
    if (!latestPricing) {
      return [];
    }
    const minYearData = Math.min(...latestPricing.data.map((item) => item.year));
    const yearOne = latestPricing.data.find((w) => w.year === minYearData);
    if (!yearOne) {
      return [];
    }
    return yearOne.yearData;
  },
);

export const getPricingResultsYearTwo = createSelector(
  getLatestPricingWithFloorPrice,
  (latestPricing) => {
    if (!latestPricing) {
      return [];
    }
    const maxYearData = Math.max(...latestPricing.data.map((item) => item.year));
    const yearTwo = latestPricing.data.find((w) => w.year === maxYearData);
    if (!yearTwo) {
      return [];
    }
    return yearTwo.yearData;
  },
);

export const getPricingSummaryYearOne = createSelector(
  getPricingResultsYearOne,
  (pricingYearOne: IWeekPricingData[]) => {
    const yearOneSummary = getYearSummary(pricingYearOne);
    return yearOneSummary;
  },
);

export const getPricingSummaryYearTwo = createSelector(
  getPricingResultsYearTwo,
  (pricingYearTwo: IWeekPricingData[]) => {
    const yearTwoSummary = getYearSummary(pricingYearTwo);
    return yearTwoSummary;
  },
);

export const getCompletePricingSummaryYearOne = createSelector(
  getPricingResultsYearOne,
  (pricingYearOne: IWeekPricingData[]) => {
    const yearOneSummary = getCompleteYearSummary(pricingYearOne);
    return yearOneSummary;
  },
);

export const getCompletePricingSummaryYearTwo = createSelector(
  getPricingResultsYearTwo,
  (pricingYearTwo: IWeekPricingData[]) => {
    const yearTwoSummary = getCompleteYearSummary(pricingYearTwo);
    return yearTwoSummary;
  },
);

export const getRevenueResults = (state: RootState): IRevenueResult[] =>
  state.engine.revenueResults;

export const getShortBreakResults = (state: RootState): AdjustedResult<IShortBreakResult> =>
  state.engine.shortBreakResults;

export const getLatestShortBreak = createSelector(
  getShortBreakResults,
  (shortBreakResult: AdjustedResult<IShortBreakResult>): AdjustedResultItem<IShortBreakResult> => {
    if (shortBreakResult.owner && shortBreakResult.owner.reason !== '') {
      return shortBreakResult.owner;
    }

    return shortBreakResult.engine;
  },
);

export const getShortBreakPanelData = createSelector(
  getLatestShortBreak,
  (shortBreakResults: AdjustedResultItem<IShortBreakResult> | null) => {
    if (!shortBreakResults) {
      return [];
    }
    return shortBreakResults.data.arrivalDistribution.map((el: IShortBreakArrivalDistribution) => {
      return {
        nights: el.type.nights,
        type: el.type.type,
        factor: el.factor,
      };
    });
  },
);

export const getShortbreakWeeks = createSelector(
  getLatestShortBreak,
  (shortBreakResults: AdjustedResultItem<IShortBreakResult> | null): IWeekStayTypeBreakdown[] =>
    shortBreakResults ? shortBreakResults.data.weeks : [],
);

export const selectShortBreakArrivalDistribution = createSelector(
  getLatestShortBreak,
  (
    shortBreakResults: AdjustedResultItem<IShortBreakResult> | null,
  ): IShortBreakArrivalDistribution[] =>
    shortBreakResults ? shortBreakResults.data.arrivalDistribution : [],
);

export const selectShortBreakPriceDistribution = createSelector(
  getLatestShortBreak,
  (shortBreakResults: AdjustedResultItem<IShortBreakResult> | null): IStayTypeValue[] =>
    shortBreakResults ? shortBreakResults.data.priceDistribution : [],
);

export const getStayTypePriceRange = createSelector(
  getShortbreakWeeks,
  (shortbreakWeeks: IWeekStayTypeBreakdown[]): IStayTypeRange[] => {
    return shortbreakWeeks.reduce((acc: IStayTypeRange[], pricesObject): IStayTypeRange[] => {
      pricesObject.prices.forEach((stayTypePrice) => {
        let stayTypeRange = acc.find(
          ({ type, nights }) => type === stayTypePrice.type && nights === stayTypePrice.nights,
        );

        if (!stayTypeRange) {
          stayTypeRange = {
            type: stayTypePrice.type,
            nights: stayTypePrice.nights,
            range: {
              lowest: Infinity,
              highest: 0,
            },
          };
          acc.push(stayTypeRange);
        }

        stayTypeRange.range.highest = Math.max(stayTypeRange.range.highest, stayTypePrice.value);
        stayTypeRange.range.lowest = Math.min(stayTypeRange.range.lowest, stayTypePrice.value);
      });
      return acc;
    }, []);
  },
);

export const getAveragePriceChange = (state: RootState): IAveragePriceChangeResult[] =>
  state.engine.apcResults;

export const getComputingBenchmarkProperties = (state: RootState): boolean =>
  state.engine.computingBenchmarkProperties;
