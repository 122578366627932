import { ActionType, getType } from 'typesafe-actions';

import { AnyAction } from 'redux';
import { ICountry, ICurrentUser } from 'services/appDatabase';
import {
  currentUserAction,
  storeCountries,
  updateDataVersionPollInterval,
  upstreamData,
} from './actions';

export type UpstreamAction = ActionType<
  | typeof upstreamData.success
  | typeof upstreamData.failure
  | typeof upstreamData.request
  | typeof currentUserAction.success
  | typeof storeCountries
  | typeof updateDataVersionPollInterval
>;

export type UpstreamState = Readonly<{
  fetched: boolean;
  fetching: boolean;
  currentUser: ICurrentUser | null;
  countries: ICountry[];
  dataVersionPollInterval: number;
}>;

const initialState: UpstreamState = {
  fetched: false,
  fetching: false,
  currentUser: null,
  countries: [],
  dataVersionPollInterval: 10 * 60 * 1000,
};

const reducer = (state: UpstreamState = initialState, action: AnyAction) => {
  const typedAction = action as UpstreamAction;
  switch (typedAction.type) {
    case getType(upstreamData.request):
      return { ...state, fetched: false, fetching: true };
    case getType(upstreamData.success):
      return { ...state, fetched: true, fetching: false };
    case getType(upstreamData.failure):
      return { ...state, fetched: false, fetching: false };
    case getType(currentUserAction.success):
      return { ...state, currentUser: action.payload.currentUser };
    case getType(storeCountries):
      return { ...state, countries: action.payload };
    case getType(updateDataVersionPollInterval):
      return { ...state, dataVersionPollInterval: action.payload };

    default:
      return state;
  }
};

export default reducer;
