import { COUNTRY_CODES } from 'constants/countries';
import { IReason, USERS } from 'constants/dropdownOptions';
import { QuoteStatus } from 'constants/quotes';
import _flatMap from 'lodash/flatMap';
import { DateTime } from 'luxon';
import { ISeasonPrices } from 'pages/Quote/Quote';
import { toast } from 'react-toastify';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import appDB, {
  Currencies,
  ICurrency,
  INewProperty,
  INewPropertyQuote,
  ISeason,
  IUpstreamProperty,
} from 'services/appDatabase';
import { applySeasonPrice } from 'services/averagePriceChange';
import engine from 'services/engine';
import { PricingEngineException } from 'services/engine/exceptions';
import {
  IAveragePriceChangeResult,
  IMarkupResult,
  IOccupancyResult,
  IPricingResult,
  IRevenueResult,
  ISeasonalPrices,
  IShortBreakResult,
  IWeekPricingData,
} from 'services/engine/types';
import { getSeasonsForCalendar } from 'services/engine/utils';
import { endEngineLogs, startEngineLogs } from 'services/logging';
import { calculateMarkup, calculateMarkupForDenmark, calculateOwnerPrices } from 'services/markup';
import client from 'services/networking/request';
import { CurrencyState } from 'stateManagement/Currency/reducer';
import {
  getCurrencies,
  getEngineCurrency,
  getEuroCurrency,
  getGuestCurrency,
  getOwnerCurrency,
} from 'stateManagement/Currency/selectors';
import { dequeuePropertiesManualRequest } from 'stateManagement/Downstream/actions';
import { cleanNewProperty } from 'stateManagement/Downstream/clean';
import {
  FetchAndLoadQuoteAction,
  LoadQuoteAction,
  UpdateCommissionRateAction,
} from 'stateManagement/Quote';
import quote, { loadQuote, updateCommissionRate } from 'stateManagement/Quote/actions';
import { IBrandFeatures } from 'stateManagement/Quote/features';
import {
  getNewProperty,
  getQuoteCommissionRate,
  getQuoteDefaultCommissionRate,
  getQuoteFeatures,
  getRawQuote,
  getUseGuestPrice,
  getUseGuestPriceCoefficients,
} from 'stateManagement/Quote/selectors';
import { ActionType, getType } from 'typesafe-actions';
import {
  adjustMarkup,
  adjustOccupancy,
  adjustPricing,
  adjustShortBreaks,
  clearPricingResult,
  computeAll,
  selectProperties,
  selectPropertiesStart,
  storeAPCResult,
  storeBaseOccupancy,
  storeFinalOccupancy,
  storeMarkupResult,
  storeOwnerPrice,
  storeOwnerPriceInitial,
  storePricingResult,
  storeRevenueResult,
  storeSeasonPrices,
  storeShortBreakAdjustment,
  storeShortBreakResult,
} from './actions';
import {
  AdjustedResult,
  AdjustedResultItem,
  EngineComputeAllAction,
  EngineShortBreakAdjustmentStoreAction,
  initialState,
} from './reducer';
import {
  getAdjustedPricing,
  getAveragePriceChange,
  getBDMOccupancy,
  getLatestOccupancy,
  getLatestPricing,
  getLatestPricingWithFloorPrice,
  getLatestShortBreak,
  getPricingResults,
  getPricingResultsYearOne,
  getPricingResultsYearTwo,
  getProperties,
  getRevenueResults,
} from './selectors';

const consoleAndToastError = (error: Error) => {
  console.warn(error);
  toast.error(`Engine error: ${error.message}`, {
    position: toast.POSITION.BOTTOM_RIGHT,
    closeButton: true,
    closeOnClick: true,
  });
};

export function* engineSelectPropertiesSaga() {
  try {
    const newProperty: INewProperty = yield select(getNewProperty);
    yield put(selectPropertiesStart());
    // eslint-disable-next-line
    console.time('PROPERTIES');
    const benchmarkProperties: IUpstreamProperty[] = yield call(
      [engine, engine.selectBenchmarkProperties],
      newProperty,
    );
    // eslint-disable-next-line
    console.timeEnd('PROPERTIES');
    // eslint-disable-next-line
    console.log('Similar properties:', benchmarkProperties);

    if (benchmarkProperties && benchmarkProperties.length > 0) {
      yield put(selectProperties.success({ properties: benchmarkProperties }));
    } else {
      yield put(
        selectProperties.failure({
          errorMessage: `No properties found with bedrooms: ${newProperty.bedrooms}
        and grade: ${newProperty.grade}.`,
        }),
      );
    }
  } catch (e) {
    consoleAndToastError(e as Error);
  }
}

function* fetchAndLoadQuoteSaga(action: FetchAndLoadQuoteAction): any {
  const downstreamPropertiesEndpoint = '/api/downstream/new_property/' + action.payload;
  const newProperty = yield call([client, client.get], downstreamPropertiesEndpoint);
  const cleanedNewProperty = cleanNewProperty(newProperty);
  yield put(loadQuote(cleanedNewProperty));
}

function* loadQuoteSaga(action: LoadQuoteAction) {
  try {
    const newProperty: INewPropertyQuote = action.payload;
    // eslint-disable-next-line
    console.time('PROPERTIES');

    const benchmarkProperties: IUpstreamProperty[] = yield appDB.upstreamProperties
      .where('uuid')
      .anyOf(newProperty.benchmarkProperties)
      .toArray();

    // eslint-disable-next-line
    console.timeEnd('PROPERTIES');

    yield put(
      quote.loadQuoteEngine({
        properties: benchmarkProperties || [],
        pricingResults: newProperty.pricingResults ?? initialState.pricingResults,
        newSeasonPrices: newProperty.newSeasonPrices ?? initialState.newSeasonPrices,
        shortBreakResults: newProperty.shortBreakResults ?? initialState.shortBreakResults,
        occupancyResults: newProperty.occupancies ?? initialState.occupancyResults,
        revenueResults: newProperty.revenues ?? initialState.revenueResults,
        apcResults: newProperty.apcResults ?? initialState.apcResults,
        floorPrice: newProperty.floorPrice ?? initialState.floorPrice,
        computingBenchmarkProperties: false,
        markupResults: newProperty.markups ?? initialState.markupResults,
      }),
    );
  } catch (e) {
    consoleAndToastError(e as Error);
  }
}

export function* computeAllSaga(action: EngineComputeAllAction) {
  try {
    if (action.payload.recalculateBenchmark) {
      yield call(engineSelectPropertiesSaga);
    }

    startEngineLogs('ENGINE', false);
    yield call(resetCommissionRateToDefault);
    yield call(computeBaseOccupancy);
    yield call(computePricing);
    yield call(computeSeasonPricesSaga);
    yield put(
      quote.update({
        status: QuoteStatus.DRAFT,
        individualSeasonPricing: false,
      }),
    );

    endEngineLogs('ENGINE');
  } catch (e) {
    endEngineLogs('ENGINE');
    consoleAndToastError(e as Error);
  }
}

export function* adjustPricingSaga(action: ActionType<typeof adjustPricing>): any {
  startEngineLogs('PRICE ADJUSTMENT', false);
  try {
    yield call(computeShortbreak);
    yield call(computeSeasonPricesSaga);

    // Trigger recalculation of owner prices by calling computeOwnerPrices saga using current commission rate.
    const commissionRate = yield select(getQuoteCommissionRate);
    const ownerPriceAction: UpdateCommissionRateAction = {
      ...action,
      type: getType(updateCommissionRate),
      payload: {
        ...action.payload,
        adjustment: commissionRate,
      },
    };
    yield call(computeOwnerPrices, ownerPriceAction);

    yield put(dequeuePropertiesManualRequest());
  } catch (e) {
    consoleAndToastError(e as Error);
  }
  endEngineLogs('PRICE ADJUSTMENT');
}

const anyPricesNegative = (result: IPricingResult[]) =>
  _flatMap(result, (item) => item.yearData).filter((item) => item.price < 0);

export function* computePricing() {
  startEngineLogs('PRICING');
  try {
    const newProperty: INewProperty = yield select(getNewProperty);
    const benchmarkProperties: IUpstreamProperty[] = yield select(getProperties);

    const useGuestPrice: boolean = yield select(getUseGuestPrice);
    const useGuestPriceCoefficients: boolean = yield select(getUseGuestPriceCoefficients);

    let computePricingFunction: (
      newProperty: INewProperty,
      benchmarkProperties: IUpstreamProperty[],
      currencies: Currencies,
    ) => Promise<IPricingResult[]>;

    if (!useGuestPrice) {
      computePricingFunction = engine.computePricing;
    } else if (useGuestPriceCoefficients) {
      computePricingFunction = engine.computePricingWithGuestCoeffs;
    } else {
      computePricingFunction = engine.computePricingN2S;
    }

    const currencyState: CurrencyState = yield select(getCurrencies);

    if (currencyState.guestCurrency == null || currencyState.ownerCurrency == null) {
      throw new Error('Could not find owner or guest currency');
    }

    const currencies = {
      ownerCurrency: currencyState.ownerCurrency,
      guestCurrency: currencyState.guestCurrency,
    };

    const result: IPricingResult[] = yield call(
      [engine, computePricingFunction],
      newProperty,
      benchmarkProperties,
      currencies,
    );
    endEngineLogs('PRICING');

    const negativePrices = anyPricesNegative(result);
    if (negativePrices.length > 0) {
      // eslint-disable-next-lineno-console
      console.warn('Negative price in pricing result', negativePrices);
      toast.error(
        'We cannot price this property with the given concepts and characteristics selected',
        {
          position: toast.POSITION.BOTTOM_RIGHT,
          closeButton: true,
          closeOnClick: true,
        },
      );
      yield put(clearPricingResult());
    } else {
      // Save new engine pricing
      yield put(storePricingResult(result));

      // Move on to short break calculation
      yield call(computeShortbreak);
    }
  } catch (e) {
    endEngineLogs('PRICING');
    consoleAndToastError(e as Error);
  }
}

export function* computeSeasonPricesSaga(): any {
  startEngineLogs('SEASON PRICES');
  try {
    const pricingResults: AdjustedResult<IPricingResult[]> = yield select(getPricingResults);
    const ownerCurrency: ICurrency = yield select(getOwnerCurrency);

    const useGuestPrice: boolean = yield select(getUseGuestPrice);
    const computeSeasonPricesFunction = useGuestPrice
      ? engine.computeSeasonPricesN2S
      : engine.computeSeasonPrices;

    const seasonPrices: ISeasonPrices = yield call(
      [engine, computeSeasonPricesFunction],
      pricingResults,
      ownerCurrency,
    );
    yield put(storeSeasonPrices(seasonPrices));
  } catch (e) {
    consoleAndToastError(e as Error);
  }

  endEngineLogs('SEASON PRICES');
}

function* onCommissionRateUpdate(action?: UpdateCommissionRateAction) {
  yield call(computeOwnerPrices, action);
  yield call(computeRevenue);
}
function* computeMarkup(action?: ActionType<typeof adjustMarkup>): any {
  startEngineLogs('MARKUP');

  try {
    const country = yield appDB.countries.toCollection().first();
    const newProperty: INewProperty = yield select(getNewProperty);

    const ownerCurrency: ICurrency = yield select(getOwnerCurrency);
    const engineCurrency: ICurrency = yield select(getEngineCurrency);
    const guestCurrency: ICurrency = yield select(getGuestCurrency);
    const euroCurrency: ICurrency = yield select(getEuroCurrency);
    const pricingResultsYearOne: IWeekPricingData[] = yield select(getPricingResultsYearOne);
    const adjustments = action ? action.payload.adjustment : undefined;

    let markupResult: IMarkupResult;

    const S1_2022 = DateTime.fromISO('2022-01-08T00:00:00.000+01:00');

    if (country.code === COUNTRY_CODES.DENMARK && newProperty.firstAvailable < S1_2022) {
      // eslint-disable-next-lineno-console
      console.log('️️⚙️ 🇩🇰 Applying danish formula ...');
      const quoteFeatures: IBrandFeatures = yield select(getQuoteFeatures);
      const revenueResults: IRevenueResult[] = yield select(getRevenueResults);

      markupResult = yield call(
        calculateMarkupForDenmark,
        newProperty,
        engineCurrency,
        ownerCurrency,
        guestCurrency,
        euroCurrency,
        pricingResultsYearOne,
        quoteFeatures,
        revenueResults,
        adjustments,
      );
    } else {
      // eslint-disable-next-lineno-console
      console.log('⚙️ 🇪🇺 Applying standard formula ...');

      markupResult = yield call(
        calculateMarkup,
        newProperty,
        engineCurrency,
        ownerCurrency,
        guestCurrency,
        euroCurrency,
        pricingResultsYearOne,
        adjustments,
      );
    }
    const engineReason: IReason = {
      label: '',
      value: 0,
      code: 'INIT',
      adjustedBy: USERS.ENGINE,
    };

    yield put(
      storeMarkupResult({
        reason: action ? action.payload.reason : engineReason,
        note: action ? action.payload.note : '',
        adjustment: markupResult,
      }),
    );
    endEngineLogs('MARKUP');
  } catch (e) {
    endEngineLogs('MARKUP');
    consoleAndToastError(e as Error);
  }
}

export function* computeOwnerPrices(action?: UpdateCommissionRateAction): any {
  startEngineLogs('NEW OWNER PRICES (FROM COMMISSION)');

  try {
    const newProperty: INewProperty = yield select(getNewProperty);
    const ownerCurrency: ICurrency = yield select(getOwnerCurrency);
    const guestCurrency: ICurrency = yield select(getGuestCurrency);
    const pricingResultsYearOne: IWeekPricingData[] = yield select(getPricingResultsYearOne);
    const pricingResultsYearTwo: IWeekPricingData[] = yield select(getPricingResultsYearTwo);

    const commissionRate = yield select(getQuoteCommissionRate);

    const quote = yield select(getRawQuote);
    const calendarUUID = quote.calendar;
    const seasons: ISeason[] = yield getSeasonsForCalendar(calendarUUID);

    const newCommissionRate = action?.payload?.adjustment ?? commissionRate;

    // eslint-disable-next-lineno-console
    console.log('⚙️ 🇪🇺 Applying standard formula ...');

    const newOwnerPricesYearOne: ISeasonalPrices = yield call(
      calculateOwnerPrices,
      newProperty,
      ownerCurrency,
      guestCurrency,
      pricingResultsYearOne,
      newCommissionRate,
    );

    const newOwnerPricesYearTwo: ISeasonalPrices = yield call(
      calculateOwnerPrices,
      newProperty,
      ownerCurrency,
      guestCurrency,
      pricingResultsYearTwo,
      newCommissionRate,
    );

    const yearOneData = pricingResultsYearOne.map((week) =>
      applySeasonPrice(week, seasons, newOwnerPricesYearOne),
    );

    const yearTwoData = pricingResultsYearTwo.map((week) =>
      applySeasonPrice(week, seasons, newOwnerPricesYearTwo),
    );

    // If no action is passed we're dealing with first (initial) compute
    const initialCompute = !action;
    if (initialCompute) {
      yield put(
        storeOwnerPriceInitial({
          yearOne: {
            year: yearOneData[0].year,
            yearData: yearOneData,
          },
          yearTwo: {
            year: yearTwoData[0].year,
            yearData: yearTwoData,
          },
        }),
      );
    }

    // If action was passed then we're dealing with either one of the two:
    // - commission rate update
    // - price adjustment in 'Seasonal Guest Price Summary' table
    const adjustmentCompute = action && action.payload;
    if (adjustmentCompute) {
      const reason = action?.payload?.reason as IReason;
      const note = action?.payload?.note as string;
      yield put(
        storeOwnerPrice({
          reason,
          note,
          adjustment: {
            yearOne: {
              year: yearOneData[0].year,
              yearData: yearOneData,
            },
            yearTwo: {
              year: yearTwoData[0].year,
              yearData: yearTwoData,
            },
          },
        }),
      );
    }

    yield call(computeSeasonPricesSaga);

    endEngineLogs('NEW OWNER PRICES (FROM COMMISSION)');
  } catch (e) {
    endEngineLogs('NEW OWNER PRICES (FROM COMMISSION)');
    consoleAndToastError(e as Error);
  }
}

export function* computeShortbreak() {
  startEngineLogs('SHORTBREAK');
  try {
    const newProperty: INewProperty = yield select(getNewProperty);
    const benchmarkProperties: IUpstreamProperty[] = yield select(getProperties);
    const pricingResults: AdjustedResultItem<IPricingResult[]> | null = yield select(
      getLatestPricingWithFloorPrice,
    );
    if (!pricingResults) {
      throw new PricingEngineException('No pricing result for shortbreak computation');
    }

    const result: IShortBreakResult = yield call(
      [engine, engine.computeShortBreak],
      newProperty,
      benchmarkProperties,
      pricingResults.data,
    );
    endEngineLogs('SHORTBREAK');

    // Save new engine short break
    yield put(storeShortBreakResult(result));

    // Move on to average price change calculation
    yield call(computeAPC);
  } catch (e) {
    endEngineLogs('SHORTBREAK');
    consoleAndToastError(e as Error);
  }
}

function* onShortbreakAdjustment(action: EngineShortBreakAdjustmentStoreAction) {
  try {
    startEngineLogs('PRICE ADJUSTED SHORTBREAK');
    const newProperty: INewProperty = yield select(getNewProperty);
    const benchmarkProperties: IUpstreamProperty[] = yield select(getProperties);
    const pricingResults: AdjustedResultItem<IPricingResult[]> | null = yield select(
      getLatestPricingWithFloorPrice,
    );
    if (!pricingResults) {
      throw new PricingEngineException('No pricing result for shortbreak computation');
    }

    const result: IShortBreakResult = yield call(
      [engine, engine.computeShortBreak],
      newProperty,
      benchmarkProperties,
      pricingResults.data,
      action.payload.adjustment.priceDistribution,
    );
    endEngineLogs('PRICE ADJUSTED SHORTBREAK');

    // Save new engine short break
    yield put(
      storeShortBreakAdjustment({
        reason: action.payload.reason,
        note: action.payload.note,
        adjustment: result,
      }),
    );

    // Move on to average price change calculation
    yield call(computeAPC);
  } catch (e) {
    endEngineLogs('PRICE ADJUSTED SHORTBREAK');
    consoleAndToastError(e as Error);
  }
}

function* computeAPC() {
  try {
    startEngineLogs('AVERAGE PRICE CHANGE');
    const newProperty: INewProperty = yield select(getNewProperty);
    const benchmarkProperties: IUpstreamProperty[] = yield select(getProperties);
    const { data: ownerShortBreak }: AdjustedResultItem<IShortBreakResult> = yield select(
      getLatestShortBreak,
    );

    const adjustedPricingResults: AdjustedResultItem<IPricingResult[]> | null = yield select(
      getAdjustedPricing,
    );
    if (!adjustedPricingResults) {
      throw new PricingEngineException('No adjusted pricing results found for APC computation');
    }
    const enginePricingResults: AdjustedResultItem<IPricingResult[]> | null = yield select(
      getLatestPricing,
    );
    if (!enginePricingResults) {
      throw new PricingEngineException('No engine pricing found for APC computation');
    }

    const result: IAveragePriceChangeResult[] = yield call(
      [engine, engine.computeAPC],
      newProperty,
      benchmarkProperties,
      ownerShortBreak,
      adjustedPricingResults.data,
      enginePricingResults.data,
    );
    endEngineLogs('AVERAGE PRICE CHANGE');

    // Save new engine APC
    yield put(storeAPCResult(result));

    // Move on to final occupancy calculation
    yield call(computeFinalOccupancy);
  } catch (e) {
    endEngineLogs('AVERAGE PRICE CHANGE');
    consoleAndToastError(e as Error);
  }
}

export function* computeBaseOccupancy() {
  try {
    startEngineLogs('OCCUPANCY');
    const newProperty: INewProperty = yield select(getNewProperty);
    const benchmarkProperties: IUpstreamProperty[] = yield select(getProperties);
    const result: IOccupancyResult[] = yield call(
      [engine, engine.computeOccupancy],
      newProperty,
      benchmarkProperties,
    );
    endEngineLogs('OCCUPANCY');

    // Save new engine base occupancy
    yield put(storeBaseOccupancy(result));
  } catch (e) {
    endEngineLogs('OCCUPANCY');
    consoleAndToastError(e as Error);
  }
}

// In N2S we reset commission rate to default for each calculation
export function* resetCommissionRateToDefault() {
  try {
    const useGuestPrice: boolean = yield select(getUseGuestPrice);
    if (useGuestPrice) {
      yield put(quote.update({ commissionRate: yield select(getQuoteDefaultCommissionRate) }));
    }
  } catch (e) {
    consoleAndToastError(e as Error);
  }
}

function* computeFinalOccupancy() {
  try {
    startEngineLogs('PRICE ADJUSTED OCCUPANCY');
    const newProperty: INewProperty = yield select(getNewProperty);
    const previousOccupancyResults: AdjustedResultItem<IOccupancyResult[]> = yield select(
      getBDMOccupancy,
    );
    const averagePriceChangeResults: IAveragePriceChangeResult[] = yield select(
      getAveragePriceChange,
    );
    const result: IOccupancyResult[] = yield call(
      [engine, engine.computePriceAdjustedOccupancy],
      newProperty,
      previousOccupancyResults.data,
      averagePriceChangeResults,
    );
    endEngineLogs('PRICE ADJUSTED OCCUPANCY');

    // Save new engine final occupancy
    yield put(storeFinalOccupancy(result));

    // Move on to revenue
    yield call(computeRevenue);
  } catch (e) {
    endEngineLogs('PRICE ADJUSTED OCCUPANCY');
    consoleAndToastError(e as Error);
  }
}

function* computeRevenue(): any {
  try {
    startEngineLogs('REVENUE');
    // Compute the Guest Price
    const useGuestPrice = yield select(getUseGuestPrice);
    if (useGuestPrice) {
      yield call(computeOwnerPrices);
    } else {
      yield call(computeMarkup);
    }

    const newProperty: INewProperty = yield select(getNewProperty);
    const occupancyResults: AdjustedResultItem<IOccupancyResult[]> | null = yield select(
      getLatestOccupancy,
    );
    const pricingResults: AdjustedResultItem<IPricingResult[]> | null = yield select(
      getLatestPricingWithFloorPrice,
    );
    if (!pricingResults) {
      throw new PricingEngineException('No pricing result for revenue computation');
    }
    if (!occupancyResults) {
      throw new PricingEngineException('No occupancy result for revenue computation');
    }

    const result = yield call(
      [engine, engine.computeRevenue],
      pricingResults.data,
      occupancyResults.data,
      newProperty,
    );
    endEngineLogs('REVENUE');

    // Save new engine final occupancy
    yield put(storeRevenueResult(result));

    // set draft status if this is the first time we calculate revenue
    if (newProperty.status === QuoteStatus.POPULATING) {
      yield put(quote.update({ status: QuoteStatus.DRAFT }));
    }
  } catch (e) {
    endEngineLogs('REVENUE');
    consoleAndToastError(e as Error);
  }
}

export default function* engineSagas() {
  yield takeLatest(
    [getType(selectProperties.request), getType(quote.create)],
    engineSelectPropertiesSaga,
  );
  yield takeLatest(getType(computeAll), computeAllSaga);
  yield takeLatest(getType(quote.loadQuote), loadQuoteSaga);
  yield takeLatest(getType(quote.fetchAndLoadQuote), fetchAndLoadQuoteSaga);
  yield takeLatest(getType(adjustPricing), adjustPricingSaga);
  yield takeLatest(getType(adjustShortBreaks), onShortbreakAdjustment);
  yield takeLatest(getType(adjustOccupancy), computeFinalOccupancy);
  yield takeLatest(getType(adjustMarkup), computeMarkup);
  yield takeLatest(getType(updateCommissionRate), onCommissionRateUpdate);
}
