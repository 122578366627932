import { QuoteQueueStatus, QuoteStatus } from 'constants/quotes';
import { REHYDRATE } from 'redux-persist';
import { eventChannel } from 'redux-saga';
import {
  call,
  debounce,
  delay,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import appDB, { ICountry, ICurrency, INewPropertyQuote } from 'services/appDatabase';
import client from 'services/networking/request';
import {
  computeAll,
  selectProperties,
  storeAPCResult,
  storeBaseOccupancy,
  storeFinalOccupancy,
  storePricingResult,
  storeRevenueResult,
  storeSeasonPrices,
  storeShortBreakResult,
} from 'stateManagement/Engine';
import {
  create as quoteCreate,
  saveOwnerWeeks,
  update as quoteUpdate,
} from 'stateManagement/Quote';
import { ActionType, getType } from 'typesafe-actions';
import {
  dequeuePropertiesManualRequest,
  downloadNewProperties,
  uploadNewProperty,
} from './actions';
import { cleanNewProperties } from './clean';
import { formatQuote } from './format';
import { getNewPropertyQuote } from './selectors';

const getQueuedOrUploadingOrFailedProperties = async (): Promise<INewPropertyQuote[]> =>
  await appDB.newProperties
    .where('queueStatus')
    .anyOf([QuoteQueueStatus.UPLOADING, QuoteQueueStatus.QUEUED, QuoteQueueStatus.FAILED])
    .toArray();

function* updateDownstreamDataSaga(): any {
  if (!navigator.onLine) {
    console.debug('%c🔌 Offline:' + 'not downloading new properties.', 'font-weight: bold;');
    yield put(downloadNewProperties.success({}));
    return;
  }

  const pendingProperties: INewPropertyQuote[] = yield call(getQueuedOrUploadingOrFailedProperties);
  const pendingPropertiesUUIDS = pendingProperties.map((p) => p.uuid);

  const downstreamPropertiesEndpoint = '/api/downstream/new_property/';
  const newProperties = yield call([client, client.get], downstreamPropertiesEndpoint);

  const cleanedNewProperties = cleanNewProperties(newProperties);

  const backendPropertiesWithoutPendingOnes = cleanedNewProperties.filter(
    (property) => !pendingPropertiesUUIDS.includes(property.uuid),
  );

  const propertiesToAdd = [...backendPropertiesWithoutPendingOnes, ...pendingProperties];

  try {
    yield call([appDB.newProperties, appDB.newProperties.clear]);
    yield call([appDB.newProperties, appDB.newProperties.bulkAdd], propertiesToAdd);
    yield put(downloadNewProperties.success({}));
  } catch (e) {
    // eslint-disable-next-lineno-console
    console.error('[Downstream]Failed to add new properties:', e);
    yield put(
      downloadNewProperties.failure({ errorMessage: '[Download] Failed to add new properties' }),
    );
  }
}

const getPropertiesToUpload = async () =>
  // @ts-ignore
  await appDB.newProperties
    .where('[queueStatus+status]')
    .anyOf([
      [QuoteQueueStatus.QUEUED, QuoteStatus.DELETED],
      [QuoteQueueStatus.QUEUED, QuoteStatus.DRAFT],
      [QuoteQueueStatus.QUEUED, QuoteStatus.SUBMITTED],
    ])
    .toArray();

export function* uploadDownstreamDataSaga(
  action: ActionType<typeof uploadNewProperty.request>,
): any {
  const quote = action.payload;

  const countriesCollection = yield call([appDB.countries, appDB.countries.toCollection]);
  const country: ICountry = yield call([countriesCollection, countriesCollection.first]);
  const countryCurrency: ICurrency = yield call(
    // @ts-ignore-next-line
    [appDB.currencies, appDB.currencies.get],
    country.currency,
  );

  try {
    yield call([appDB.newProperties, appDB.newProperties.update], quote.uuid, {
      queueStatus: QuoteQueueStatus.UPLOADING,
    });
    console.debug(
      `%c⬆️ Uploading property ${quote.uuid.substr(0, 6)}` + '...',
      'font-weight: bold;',
    );

    let newPropertyResponse;

    const formattedQuote = yield call(formatQuote, quote, countryCurrency);

    if (!quote.persisted) {
      const newPropertyEndpoint = `/api/downstream/new_property/`;
      newPropertyResponse = yield call([client, client.post], newPropertyEndpoint, formattedQuote);
    } else {
      const newPropertyEndpoint = `/api/downstream/new_property/${quote.uuid}/`;
      newPropertyResponse = yield call([client, client.put], newPropertyEndpoint, formattedQuote);
    }

    console.debug(`%c✅ Property ${quote.uuid.substr(0, 6)}: upload success`, 'font-weight: bold;');

    // if the quote being updated is in the reducer, the reducer will update its state
    yield put(
      uploadNewProperty.success({
        uuid: quote.uuid,
        status: newPropertyResponse.status,
        persisted: newPropertyResponse.persisted as boolean,
      }),
    );
    const previousQuoteState = yield call(
      // @ts-ignore
      [appDB.newProperties, appDB.newProperties.get],
      quote.uuid,
    );

    yield call([appDB.newProperties, appDB.newProperties.update], quote.uuid, {
      queueStatus:
        previousQuoteState.queueStatus === QuoteQueueStatus.QUEUED
          ? previousQuoteState.queueStatus
          : QuoteQueueStatus.UPLOADED,
      status:
        previousQuoteState.queueStatus === QuoteQueueStatus.QUEUED
          ? previousQuoteState.status
          : newPropertyResponse.status,
      errorMessage: null,
      persisted: newPropertyResponse.persisted as boolean,
    });
  } catch (error: any) {
    console.debug('TCL: function*uploadDownstreamDataSaga -> error', error);
    console.debug(
      `%c❌ Uploading property ${quote.uuid.substr(0, 6)}: failed: ` + error.message,
      'font-weight: bold;',
    );
    let queueStatus: QuoteQueueStatus = QuoteQueueStatus.QUEUED;
    let status: QuoteStatus = quote.status;
    let errorMessage = "Error while saving quote. Will try again when we're back online";
    if (error.status === 400) {
      // Don't attempt to re-send failed quotes.
      console.debug(
        `%c  Uploading property ${quote.uuid.substr(0, 6)}: won't retry until next change`,
        'font-weight: bold;',
      );
      queueStatus = QuoteQueueStatus.FAILED;
      status = QuoteStatus.DRAFT;
      errorMessage = Object.keys(error.response.body)
        .flatMap((key) => error.response.body[key])
        .join(' ');
    }
    yield put(
      uploadNewProperty.failure({
        uuid: quote.uuid,
        errorMessage,
        queueStatus,
      }),
    );
    yield call([appDB.newProperties, appDB.newProperties.update], quote.uuid, {
      queueStatus,
      status,
      errorMessage,
    });
  }
}

const connectionChannel = (event: string) =>
  eventChannel((emitter: any) => {
    const handleFunction = () => emitter(navigator.onLine);
    window.addEventListener(event, handleFunction);
    return () => window.removeEventListener(event, handleFunction);
  });

function* dequeuePropertiesManualSaga() {
  if (navigator.onLine) {
    yield delay(1000);
    const properties: INewPropertyQuote[] = yield call(getPropertiesToUpload);
    if (properties.length > 0) {
      console.debug(
        `%c👫 ${properties.length} properties in the queue to upload`,
        'font-weight: bold;',
      );
    }

    for (const property of properties) {
      yield put(uploadNewProperty.request(property));
    }
  }
}

function* dequeuePropertiesWhenOnline(): any {
  const onlineChannel = yield call(connectionChannel, 'online');

  yield take(REHYDRATE);

  if (navigator.onLine) {
    console.debug('%c💡 Initially online:' + 'starting dequeuing process...', 'font-weight: bold;');
    yield call(dequeuePropertiesManualSaga);
  }
  while (true) {
    yield take(onlineChannel);
    console.debug('%c💡 Online:' + 'starting dequeuing process...', 'font-weight: bold;');
    yield call(dequeuePropertiesManualSaga);
  }
}

function* saveToIndexedDB() {
  const newPropertyQuote: INewPropertyQuote = yield select(getNewPropertyQuote);
  if (newPropertyQuote.uuid !== '') {
    yield call([appDB.newProperties, appDB.newProperties.put], newPropertyQuote);
  }

  yield put(dequeuePropertiesManualRequest());
}

export default function* downstreamSagas() {
  yield takeEvery(getType(uploadNewProperty.request), uploadDownstreamDataSaga);
  yield takeLatest(getType(downloadNewProperties.request), updateDownstreamDataSaga);
  yield fork(dequeuePropertiesWhenOnline);

  // Continuously save current quote in IndexedDB
  yield debounce(
    500,
    [
      quoteCreate,
      quoteUpdate,
      selectProperties.success,
      computeAll,
      storePricingResult,
      storeSeasonPrices,
      storeShortBreakResult,
      storeAPCResult,
      storeBaseOccupancy,
      storeFinalOccupancy,
      storeRevenueResult,
      saveOwnerWeeks,
    ].map(getType),
    saveToIndexedDB,
  );

  yield takeEvery(dequeuePropertiesManualRequest, dequeuePropertiesManualSaga);

  yield takeEvery(getType(uploadNewProperty.request), saveToIndexedDB);
}
