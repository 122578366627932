import { ICountry, ICurrentUser } from 'services/appDatabase';
import { createAsyncAction, createStandardAction } from 'typesafe-actions';

export const storeCountries = createStandardAction('UPSTREAM_DATA/STORE_COUNTRIES')<ICountry[]>();

export const updateDataVersionPollInterval = createStandardAction(
  'UPSTREAM_DATA/UPDATE_DATA_VERSION_POLL_INTERVAL',
)<number>();

export const upstreamData = createAsyncAction(
  'UPSTREAM_DATA/UPSTREAM_DATA_REQUEST',
  'UPSTREAM_DATA/UPSTREAM_DATA_SUCCESS',
  'UPSTREAM_DATA/UPSTREAM_DATA_FAILURE',
)<
  {},
  {},
  {
    errorMessage: string;
  }
>();

export const currentUserAction = createAsyncAction(
  'CURRENT_USER/CURRENT_USER_REQUEST',
  'CURRENT_USER/CURRENT_USER_SUCCESS',
  'CURRENT_USER/CURRENT_USER_FAILURE',
)<
  {},
  {
    currentUser: ICurrentUser;
  },
  {
    errorMessage: string;
  }
>();

export default {
  upstreamData,
  currentUserAction,
};
