import { QuoteQueueStatus, QuoteStatus } from 'constants/quotes';
import { DateTime } from 'luxon';
import { AC_OPTIONS } from 'molecules/featureComponents/novasol/AC';
import { DC_OPTIONS } from 'molecules/featureComponents/novasol/DiscountConcepts';
import { FINAL_CLEANING_OPTIONS } from 'molecules/featureComponents/novasol/FinalCleaning';
import { FISH_HOUSE_TYPES } from 'molecules/featureComponents/novasol/Fishhouse';
import { INTERNET_OPTIONS } from 'molecules/featureComponents/novasol/Internet';
import { AnyAction } from 'redux';
import { INewProperty } from 'services/appDatabase';
import { currentSiteIs } from 'services/site';
import { uploadNewProperty } from 'stateManagement/Downstream/actions';
import { IAdjustment } from 'stateManagement/Engine';
import { logout } from 'stateManagement/Login';
import { ActionType, getType } from 'typesafe-actions';
import uuid from 'uuid';
import quote from './actions';
import { IBrandFeatures } from './features';

export interface QuoteState extends Readonly<INewProperty> {
  features: IBrandFeatures;
  commissionRate: number;
  minCommissionRate: number;
  defaultCommissionRate: number;
  commissionRateAdjustmentReason: IAdjustment<number> | undefined;
  individualSeasonPricing: boolean;
  useGuestPrice: boolean;
  useGuestPriceCoefficients: boolean;
  disableReasonDialog: boolean;
  isResubmission: boolean;
}

export type CreateQuoteAction = ActionType<typeof quote.create>;
export type UpdateQuoteAction = ActionType<typeof quote.update>;
export type ResetQuoteAction = ActionType<typeof quote.reset>;
export type LoadQuoteAction = ActionType<typeof quote.loadQuote>;
export type FetchAndLoadQuoteAction = ActionType<typeof quote.fetchAndLoadQuote>;
type DownstreamRequestAction = ActionType<typeof uploadNewProperty.request>;
type DownstreamSuccessAction = ActionType<typeof uploadNewProperty.success>;
type DownstreamFailureAction = ActionType<typeof uploadNewProperty.failure>;
type SaveOwnerWeeksAction = ActionType<typeof quote.saveOwnerWeeks>;
type LogoutSuccessAction = ActionType<typeof logout.success>;
type SetGuestPriceUsageAction = ActionType<typeof quote.useGuestPrice>;
type SetDisableReasonDialogAction = ActionType<typeof quote.disableReasonDialog>;
type SetGuestPriceCoefficientsUsageAction = ActionType<typeof quote.useGuestPriceCoefficients>;
export type UpdateCommissionRateAction = ActionType<typeof quote.updateCommissionRate>;

export const initialQuoteState: QuoteState = {
  econtractId: undefined,
  addressLine1: '',
  addressLine2: '',
  areaCode: null, // Used for Danish markup calculations only
  availabilityCloseDate: '',
  availabilityOpenDate: '',
  bathrooms: 0,
  bedrooms: 0,
  useGuestPrice: false,
  useGuestPriceCoefficients: false,
  disableReasonDialog: false,
  benchmarkProperties: [],
  calendar: '',
  cascadeType: '',
  city: '',
  commissionRate: 0,
  minCommissionRate: 0,
  defaultCommissionRate: 0,
  commissionRateAdjustmentReason: undefined,
  countryRegion: '',
  featureFactors: [],
  features: {
    ac: AC_OPTIONS.NONE,
    balcony: 0,
    buildYear: undefined,
    distanceToSea: undefined,
    energy: 0,
    finalCleaning: FINAL_CLEANING_OPTIONS.NONE,
    fishhouse: FISH_HOUSE_TYPES.NONE,
    germanTv: 0,
    internet: INTERNET_OPTIONS.NONE,
    isActivityHouse: false,
    isAllInclusive: false,
    isFullyFlexible: false,
    isPetFriendly: currentSiteIs('cottages'),
    discountConcept: DC_OPTIONS.NONE,
    ownerOnPremise: false,
    plotType: 0,
    pooltype: 0,
    propertySize: undefined,
    plotSize: undefined,
    renovationYear: undefined,
    sauna: 0,
    shortBreakNovasol: false,
    viewtype: 0,
    whirlpool: 0,
    bedrooms: 0,
    bathrooms: 0,
  },
  firstAvailable: DateTime.invalid('Not yet set'),
  firstName: '',
  goLive: DateTime.invalid('Not yet set'),
  grade: 3,
  guests: 0,
  houseNumber: '',
  individualSeasonPricing: false,
  lastName: '',
  latitude: 0,
  longitude: 0,
  ownerWeeks: null,
  leadId: '',
  contractNumber: '',
  persisted: false,
  postcode: '',
  pricingRegionCode: null,
  errorMessage: null,
  propertyName: '',
  propertyType: '',
  queueStatus: QuoteQueueStatus.QUEUED,
  status: QuoteStatus.POPULATING,
  subRegion: '',
  updatedAt: '',
  uuid: '',
  salesforceUpdated: null,
  salesforceUpdateErrors: [],
  isResubmission: false,
};

const reducer = (state: typeof initialQuoteState = initialQuoteState, action: AnyAction) => {
  const typedAction = action as
    | CreateQuoteAction
    | UpdateQuoteAction
    | ResetQuoteAction
    | LoadQuoteAction
    | DownstreamSuccessAction
    | DownstreamFailureAction
    | DownstreamRequestAction
    | SaveOwnerWeeksAction
    | LogoutSuccessAction
    | SetGuestPriceUsageAction
    | SetDisableReasonDialogAction
    | SetGuestPriceCoefficientsUsageAction
    | UpdateCommissionRateAction;

  const dateNow = DateTime.local();
  switch (typedAction.type) {
    case getType(quote.saveOwnerWeeks):
      return {
        ...state,
        ownerWeeks: action.payload,
      };
    case getType(quote.create):
      return {
        ...state,
        ...typedAction.payload,
        uuid: typedAction.payload.uuid ? typedAction.payload.uuid : uuid.v4(),
        updatedAt: dateNow.toISO(),
      };
    case getType(quote.update):
      return {
        ...state,
        ...action.payload,
        commissionRate: action.payload.commissionRate
          ? action.payload.commissionRate
          : state.commissionRate,
        minCommissionRate: action.payload.minCommissionRate
          ? action.payload.minCommissionRate
          : state.minCommissionRate,
        defaultCommissionRate: action.payload.defaultCommissionRate
          ? action.payload.defaultCommissionRate
          : state.defaultCommissionRate,
        features: {
          ...state.features,
          ...(action.payload.features || {}),
        },
        goLive: action.payload.goLive || state.goLive,
        firstAvailable: action.payload.firstAvailable || state.firstAvailable,
        updatedAt: dateNow.toISO(),
        isResubmission: action.payload.isResubmission
          ? action.payload.isResubmission
          : state.isResubmission || false,
        queueStatus:
          state.status === QuoteStatus.SUBMITTED ? state.queueStatus : QuoteQueueStatus.QUEUED,
        errorMessage: null,
      };
    case getType(uploadNewProperty.request):
      return {
        ...state,
        queueStatus:
          action.payload.uuid === state.uuid ? QuoteQueueStatus.UPLOADING : state.queueStatus,
      };
    case getType(uploadNewProperty.success):
      if (action.payload.uuid === state.uuid) {
        return {
          ...state,
          queueStatus:
            state.queueStatus === QuoteQueueStatus.UPLOADING
              ? QuoteQueueStatus.UPLOADED
              : state.queueStatus,
          errorMessage: null,
          persisted: action.payload.persisted,
        };
      }
      return state;
    case getType(uploadNewProperty.failure):
      return {
        ...state,
        queueStatus:
          action.payload.uuid === state.uuid ? action.payload.newStatus : state.queueStatus,
        errorMessage:
          action.payload.uuid === state.uuid ? action.payload.errorMessage : state.errorMessage,
      };
    case getType(quote.reset):
    case getType(logout.success):
      return initialQuoteState;
    case getType(quote.loadQuote):
      return {
        ...action.payload,
        firstAvailable: DateTime.fromISO(action.payload.firstAvailable),
        goLive: DateTime.fromISO(action.payload.goLive),
      };
    case getType(quote.useGuestPrice):
      return {
        ...state,
        useGuestPrice: action.payload,
      };
    case getType(quote.useGuestPriceCoefficients):
      return {
        ...state,
        useGuestPriceCoefficients: action.payload,
      };
    case getType(quote.disableReasonDialog):
      return {
        ...state,
        disableReasonDialog: action.payload,
      };
    case getType(quote.updateCommissionRate):
      return {
        ...state,
        commissionRateAdjustment: typedAction.payload,
        commissionRate: typedAction.payload.adjustment,
        errorMessage: null,
      };
    default:
      return state;
  }
};

export default reducer;
