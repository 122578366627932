import { createAsyncAction } from 'typesafe-actions';

export const fetchFeatureFlags = createAsyncAction(
  'FETCH_FEATURE_FLAGS/REQUEST',
  'FETCH_FEATURE_FLAGS/SUCCESS',
  'FETCH_FEATURE_FLAGS/FAILURE',
)<
  undefined,
  {
    [key: string]: boolean;
  },
  {
    errorMessage: string;
  }
>();

export const updateAvailable = createAsyncAction(
  'FORCE_UPDATE/REQUEST',
  'FORCE_UPDATE/SUCCESS',
  'FORCE_UPDATE/FAILURE',
)<undefined, undefined, undefined>();

export const dataUpdateAvailable = createAsyncAction(
  'FORCE_DATA_UPDATE/REQUEST',
  'FORCE_DATA_UPDATE/SUCCESS',
  'FORCE_DATA_UPDATE/FAILURE',
)<undefined, undefined, undefined>();

export default {
  dataUpdateAvailable,
  updateAvailable,
  fetchFeatureFlags,
};
