import { IReason } from 'constants/dropdownOptions';
import { IMarkupAdjustedValues } from 'organisms/Markup/Markup';
import { ISeasonPrices } from 'pages/Quote/Quote';
import { IUpstreamProperty } from 'services/appDatabase';
import {
  IAveragePriceChangeResult,
  IMarkupResult,
  IOccupancyResult,
  IPricingResult,
  IRevenueResult,
  IShortBreakResult,
  IStayTypeValue,
  IWeekStayTypeBreakdown,
} from 'services/engine/types';
import { createAsyncAction, createStandardAction } from 'typesafe-actions';

export interface ISelectPropertiesInput {
  grade: number;
  bedrooms: number;
  postcode: string;
}

export interface ISelectPropertiesOutput {
  properties: IUpstreamProperty[];
}

interface IPricingAdjustment {
  floorPrice: number | null;
  pricing: IPricingResult[];
}

export interface IAdjustment<T> {
  reason: IReason;
  note: string;
  adjustment: T;
}

interface IShortBreakAdjustment {
  priceDistribution: IStayTypeValue[];
}

interface IShortBreakAdjustmentSave {
  priceDistribution: IStayTypeValue[];
  weeks: IWeekStayTypeBreakdown[];
}

export interface IComputeAllPayload {
  recalculateBenchmark: boolean;
}

export type IPricingAdjustmentActionPayload = IAdjustment<IPricingAdjustment>;
export type IShortBreaksAdjustmentActionPayload = IAdjustment<IShortBreakAdjustment>;
export type IShortBreaksAdjustmentSaveActionPayload = IAdjustment<IShortBreakAdjustmentSave>;
export type IOccupancyAdjustmentActionPayload = IAdjustment<IOccupancyResult[]>;
export type IMarkupAdjustmentActionPayload = IAdjustment<IMarkupAdjustedValues>;
export type IOwnerPriceAdjustmentActionPayload = IAdjustment<{
  yearOne: IPricingResult;
  yearTwo: IPricingResult;
}>;

export type IMarkupStoreAction = IAdjustment<IMarkupResult>;

export const selectProperties = createAsyncAction(
  'ENGINE/SELECT_PROPERTIES_REQUEST',
  'ENGINE/SELECT_PROPERTIES_SUCCESS',
  'ENGINE/SELECT_PROPERTIES_FAILURE',
)<
  ISelectPropertiesInput,
  ISelectPropertiesOutput,
  {
    errorMessage: string;
  }
>();

export const storeMarkupResult =
  createStandardAction('ENGINE/COMPUTE/MARKUP')<IMarkupStoreAction>();

export const adjustMarkup = createStandardAction(
  'ENGINE/ADJUSTMENT/MARKUP',
)<IMarkupAdjustmentActionPayload>();

export const selectPropertiesStart = createStandardAction('ENGINE/SELECT_PROPERTIES_START')();

export const computeAll = createStandardAction('ENGINE/COMPUTE/ALL')<IComputeAllPayload>();

export const adjustPricing = createStandardAction(
  'ENGINE/ADJUSTMENT/PRICING',
)<IPricingAdjustmentActionPayload>();

export const adjustShortBreaks = createStandardAction(
  'ENGINE/ADJUSTMENT/SHORTBREAKS',
)<IShortBreaksAdjustmentActionPayload>();

export const storeShortBreakAdjustment = createStandardAction(
  'ENGINE/ADJUSTMENT/SHORTBREAKS_SAVE',
)<IShortBreaksAdjustmentActionPayload>();

export const adjustOccupancy = createStandardAction(
  'ENGINE/ADJUSTMENT/OCCUPANCY',
)<IOccupancyAdjustmentActionPayload>();

export const storePricingResult =
  createStandardAction('ENGINE/COMPUTE/PRICING')<IPricingResult[]>();

export const storeSeasonPrices = createStandardAction(
  'ENGINE/COMPUTE/SEASON_PRICES',
)<ISeasonPrices>();

export const clearPricingResult = createStandardAction('ENGINE/CLEAR/PRICING')<void>();

export const storeShortBreakResult = createStandardAction(
  'ENGINE/COMPUTE/SHORTBREAK',
)<IShortBreakResult>();
export const storeAPCResult =
  createStandardAction('ENGINE/COMPUTE/APC')<IAveragePriceChangeResult[]>();
export const storeBaseOccupancy = createStandardAction('ENGINE/COMPUTE/BASE_OCCUPANCY')<
  IOccupancyResult[]
>();
export const storeFinalOccupancy = createStandardAction('ENGINE/COMPUTE/FINAL_OCCUPANCY')<
  IOccupancyResult[]
>();
export const storeRevenueResult = createStandardAction('ENGINE/COMPUTE/REVENUE')<IRevenueResult>();
export const clearMarkupAdjustements = createStandardAction('ENGINE/MARKUP/CLEAR_ADJUSTEMENTS')();
export const storeOwnerPrice = createStandardAction(
  'ENGINE/COMMISSION/STORE_OWNER_PRICE',
)<IOwnerPriceAdjustmentActionPayload>();

export const storeOwnerPriceInitial = createStandardAction(
  'ENGINE/COMMISSION/STORE_OWNER_PRICE_INITIAL',
)<{
  yearOne: IPricingResult;
  yearTwo: IPricingResult;
}>();

export default {
  selectProperties,
  selectPropertiesStart,
  storeAPCResult,
  storeBaseOccupancy,
  storeFinalOccupancy,
  storePricingResult,
  storeSeasonPrices,
  storeRevenueResult,
  storeShortBreakResult,
  storeMarkupResult,
  clearMarkupAdjustements,
  clearPricingResult,
  computeAll,
  adjustPricing,
  adjustShortBreaks,
  storeShortBreakAdjustment,
  adjustOccupancy,
  storeOwnerPrice,
  storeOwnerPriceInitial,
};
