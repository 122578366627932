import { ICurrency } from 'services/appDatabase';
import { ActionType, getType } from 'typesafe-actions';

import { AnyAction } from 'redux';
import { setEngineCurrency, setEuroCurrency, setGuestCurrency, setOwnerCurrency } from './actions';

export type CurrencyState = Readonly<{
  ownerCurrency: ICurrency | null;
  engineCurrency: ICurrency | null;
  guestCurrency: ICurrency | null;
  euroCurrency: ICurrency | null;
}>;

type SetEngineCurrencyAction = ActionType<typeof setEngineCurrency>;
type SetOwnerCurrencyAction = ActionType<typeof setOwnerCurrency>;
type SetGuestCurrencyAction = ActionType<typeof setGuestCurrency>;
type SetEuroCurrencyAction = ActionType<typeof setEuroCurrency>;

export type CurrencyAction =
  | SetEngineCurrencyAction
  | SetOwnerCurrencyAction
  | SetGuestCurrencyAction
  | SetEuroCurrencyAction;

const initialState: CurrencyState = {
  ownerCurrency: null,
  engineCurrency: null,
  guestCurrency: null,
  euroCurrency: null,
};

const reducer = (state: typeof initialState = initialState, action: AnyAction) => {
  const typedAction = action as CurrencyAction;
  switch (typedAction.type) {
    case getType(setOwnerCurrency):
      return {
        ...state,
        ownerCurrency: typedAction.payload,
      };
    case getType(setEngineCurrency):
      return {
        ...state,
        engineCurrency: typedAction.payload,
      };
    case getType(setEuroCurrency):
      return {
        ...state,
        euroCurrency: typedAction.payload,
      };
    case getType(setGuestCurrency):
      return {
        ...state,
        guestCurrency: typedAction.payload,
      };
    default:
      return state;
  }
};

export default reducer;
