import * as Sentry from '@sentry/browser';
import { all, call, delay, put, select } from 'redux-saga/effects';
import { sameDataVersionNumber } from 'services/dataVersion';
import client from 'services/networking/request';
import { fetchFeatureFlags } from 'stateManagement/Configuration';
import { dataUpdateAvailable, updateAvailable } from 'stateManagement/Configuration/actions';
import { getInFlightUpdates } from 'stateManagement/Downstream/selectors';
import { getDataVersionPollInterval } from 'stateManagement/Upstream/selectors';

export function* featureFlagSaga() {
  while (true) {
    const endpoint = `/feature_flags`;
    if (navigator.onLine) {
      try {
        const featureFlags: { [key: string]: boolean } = yield call([client, client.get], endpoint);
        if (Object.keys(featureFlags).length > 0) {
          yield put(fetchFeatureFlags.success(featureFlags));
        } else {
          yield put(fetchFeatureFlags.failure({ errorMessage: 'No feature flags available' }));
        }
      } catch (error) {
        yield put(fetchFeatureFlags.failure({ errorMessage: 'Failed to fetch featureFlags' }));
      }
    }
    yield delay(5 * 60 * 1000);
  }
}

export function* versionCheckSaga() {
  while (true) {
    const inFlightUpdates: number = yield select(getInFlightUpdates);
    if (inFlightUpdates === 0) {
      const endpoint = `/health`;
      if (navigator.onLine) {
        try {
          const response: { version: string } = yield call([client, client.get], endpoint);
          if (window.config.sentry.version !== response.version && response.version !== 'unknown') {
            if (navigator && navigator.serviceWorker) {
              unregisterSWs();
            }

            yield put(updateAvailable.success());
          }
        } catch (error) {
          Sentry.captureException(error);
        }
      }
    }
    yield delay(60 * 1000);
  }
}

export function* dataVersionCheckSaga() {
  while (true) {
    const dataVersionPollInterval: number = yield select(getDataVersionPollInterval);
    yield delay(dataVersionPollInterval);
    const dataVersionNumberEndpoint = '/api/upstream/version/';
    if (navigator.onLine) {
      try {
        const dataVersionInfo: { data_version_number: string } = yield call(
          [client, client.get],
          dataVersionNumberEndpoint,
        );
        const isDataVersionTheSame: boolean = yield call(
          sameDataVersionNumber,
          dataVersionInfo.data_version_number,
        );

        if (!isDataVersionTheSame) {
          yield put(dataUpdateAvailable.success());
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    }
  }
}

const unregisterSWs = async () => {
  const regs = await window.navigator.serviceWorker.getRegistrations();
  regs.forEach((registration) => registration.unregister());
  if ('caches' in window) {
    const cacheKeys = await window.caches.keys();
    cacheKeys.forEach((cacheKey) => window.caches.delete(cacheKey));
  }
};

export default function* configurationSagas() {
  yield all([featureFlagSaga(), versionCheckSaga(), dataVersionCheckSaga()]);
}
