import { ActionType, getType } from 'typesafe-actions';

import { AnyAction } from 'redux';
import { downloadNewProperties, uploadNewProperty } from './actions';

export type DownstreamAction = ActionType<
  | typeof downloadNewProperties.success
  | typeof downloadNewProperties.failure
  | typeof downloadNewProperties.request
  | typeof uploadNewProperty.request
  | typeof uploadNewProperty.success
  | typeof uploadNewProperty.failure
>;

export type DownstreamState = Readonly<{
  fetched: boolean;
  fetching: boolean;
  inFlightUpdates: number;
}>;

const initialState: DownstreamState = {
  fetched: false,
  fetching: false,
  inFlightUpdates: 0,
};

const reducer = (state: DownstreamState = initialState, action: AnyAction) => {
  const typedAction = action as DownstreamAction;
  switch (typedAction.type) {
    case getType(downloadNewProperties.request):
      return { ...state, fetched: false, fetching: true };
    case getType(downloadNewProperties.success):
      return { ...state, fetched: true, fetching: false };
    case getType(downloadNewProperties.failure):
      return { ...state, fetched: false, fetching: false };
    case getType(uploadNewProperty.request):
      return { ...state, inFlightUpdates: state.inFlightUpdates + 1 || 1 };
    case getType(uploadNewProperty.success):
      return { ...state, inFlightUpdates: state.inFlightUpdates - 1 };
    case getType(uploadNewProperty.failure):
      return { ...state, inFlightUpdates: state.inFlightUpdates - 1 };
    default:
      return state;
  }
};

export default reducer;
