import { all, call, put, takeEvery } from 'redux-saga/effects';
import appDB, {
  IBookingPerMonth,
  IBookingProbability,
  IBookingWindowPenalty,
  ICalendar,
  ICommissionRate,
  ICountry,
  ICurrency,
  ICurrencyFee,
  IMarkupData,
  IMarkupHomeInsurance,
  IMarkupSeasonalExtras,
  IOccupancyCoefficient,
  IPriceCoefficient,
  IPricingRegion,
  ISector,
  IShortBreakArrivalDistribution,
  IUpstreamProperty,
} from 'services/appDatabase';
import { sameCountry, sameDataVersionNumber, updateDataVersionNumber } from 'services/dataVersion';
import { logCurrency } from 'services/logging';
import client from 'services/networking/request';
import { dataUpdateAvailable } from 'stateManagement/Configuration/actions';
import { setEngineCurrency, setEuroCurrency } from 'stateManagement/Currency';
import { EURO_CODE } from 'stateManagement/Currency/constants';
import { ActionType, getType } from 'typesafe-actions';
import {
  currentUserAction,
  storeCountries,
  updateDataVersionPollInterval,
  upstreamData,
} from './actions';

const currentUserEndpoint = '/auth/users/me/';
const propertiesEndpoint = '/api/upstream/properties/';
const sectorsEndpoint = '/api/upstream/sectors/';
const occupancyCoefficientsEndpoint = '/api/upstream/occupancy_coefficients/';
const featuresEndpoint = '/api/upstream/features/';
const bookingPerMonthEndpoint = '/api/upstream/booking_per_month/';
const bookingWindowPenaltyEndpoint = '/api/upstream/booking_window_penalties/';
const weekMappingEndpoint = '/api/upstream/week_mappings/';
const seasonEndpoint = '/api/upstream/seasons/';
const priceCoefficientEndpoint = '/api/upstream/price_coefficients/';
const guestPriceCoefficientEndpoint = '/api/upstream/guest_price_coefficients/';
const bookingProbabilityEndpoint = '/api/upstream/booking_probabilities/';
const pricingRegionEndpoint = '/api/upstream/pricing_regions/';
const shortBreaksEndpoint = '/api/upstream/shortbreak_data/';
const calendarsEndpoint = '/api/upstream/calendars/';
const countriesEndpoint = '/api/upstream/countries/';
const countryRegionsEndpoint = '/api/upstream/country_regions/';
const commissionRatesEndpoint = '/api/upstream/commission_rates/';
const markupHomeInsuranceEndpoint = '/api/upstream/markup_home_insurances/';
const markupSeasonalExtrasEndpoint = '/api/upstream/markup_seasonal_extras/';
const areaCodeEndpoint = '/api/upstream/area_codes/';
const cascadeTypesEndpoint = '/api/upstream/cascade_types/';
const cascadeDataEndpoint = '/api/upstream/cascade_data/';
const currenciesDataEndpoint = '/api/upstream/currencies/';
const dataVersionInfoEndpoint = '/api/upstream/version/';
const priceIdMappingsEndpoint = '/api/upstream/price_mappings/';
const markupCurrencyFeesEndpoint = '/api/upstream/markup_currency_fees/';
const markupDataEndpoint = '/api/upstream/markup_data/';
const markupMappingsEndpoint = '/api/upstream/markup_mappings/';

export function* getCurrentUserSaga(): any {
  try {
    const currentUser = yield all([call([client, client.get], currentUserEndpoint)]);

    yield put(currentUserAction.success({ currentUser: currentUser[0] }));
  } catch (error) {
    yield put(currentUserAction.failure({ errorMessage: (error as Error).message }));
  }
}

function getUpstreamData() {
  return all([
    call([client, client.get], propertiesEndpoint, true),
    call([client, client.get], sectorsEndpoint),
    call([client, client.get], occupancyCoefficientsEndpoint),
    call([client, client.get], featuresEndpoint),
    call([client, client.get], bookingPerMonthEndpoint),
    call([client, client.get], bookingWindowPenaltyEndpoint),
    call([client, client.get], weekMappingEndpoint),
    call([client, client.get], seasonEndpoint),
    call([client, client.get], priceCoefficientEndpoint),
    call([client, client.get], guestPriceCoefficientEndpoint),
    call([client, client.get], bookingProbabilityEndpoint),
    call([client, client.get], pricingRegionEndpoint),
    call([client, client.get], shortBreaksEndpoint),
    call([client, client.get], calendarsEndpoint),
    call([client, client.get], countryRegionsEndpoint),
    call([client, client.get], commissionRatesEndpoint),
    call([client, client.get], markupHomeInsuranceEndpoint),
    call([client, client.get], markupSeasonalExtrasEndpoint),
    call([client, client.get], areaCodeEndpoint),
    call([client, client.get], cascadeTypesEndpoint),
    call([client, client.get], cascadeDataEndpoint),
    call([client, client.get], priceIdMappingsEndpoint),
    call([client, client.get], markupCurrencyFeesEndpoint),
    call([client, client.get], markupDataEndpoint),
    call([client, client.get], markupMappingsEndpoint),
  ]);
}

export function* updateUpstreamDataSaga(action: ActionType<typeof upstreamData.request>): any {
  if (!navigator.onLine) {
    console.debug('%c🔌 Offline:' + 'not updating upstream data.', 'font-weight: bold;');
    yield put(upstreamData.success({}));
    return;
  }

  try {
    const [dataVersionInfo, countries, currencies] = yield all([
      yield call([client, client.get], dataVersionInfoEndpoint),
      yield call([client, client.get], countriesEndpoint),
      yield call([client, client.get], currenciesDataEndpoint),
    ]);
    const cleanedCountries = cleanCountries(countries);
    const cleanedCurrencies = cleanCurrency(currencies);

    yield put(updateDataVersionPollInterval(dataVersionInfo.data_version_poll_interval));

    yield put(storeCountries(cleanedCountries));

    if (sameDataVersionNumber(dataVersionInfo.data_version_number)) {
      const isSameCountry = yield call(sameCountry, countries);
      if (isSameCountry) {
        yield put(upstreamData.success({}));
        return;
      }
    }
    yield put(dataUpdateAvailable.failure());

    appDB.clearDBExceptQuotes();

    const upstreamDataArr = yield getUpstreamData();
    const [
      properties,
      sectors,
      occupancyCoefficients,
      features,
      bookingPerMonth,
      bookingWindowPenalties,
      weekMapping,
      season,
      priceCoefficient,
      guestPriceCoefficient,
      bookingProbabilities,
      pricingRegion,
      shortBreaks,
      calendars,
      countryRegions,
      commissionRate,
      markupHomeInsurances,
      markupSeasonalExtras,
      areaCodes,
      cascadeTypes,
      cascadeData,
      priceIdMappings,
      markupCurrencyFees,
      markupData,
      markupMappings,
    ] = upstreamDataArr;

    const cleanedProperties = cleanPropertyData(properties);
    const cleanedSectors = cleanSectors(sectors);
    const cleanedOccupancyCoefficients = cleanOccupancyCoefficients(occupancyCoefficients);
    const cleanedBookingWindowPenalties = cleanBookingWindowPenalties(bookingWindowPenalties);
    const cleanedBookingPerMonth = cleanBookingPerMonth(bookingPerMonth);
    const cleanedBookingProbabilities = cleanBookingProbability(bookingProbabilities);
    const cleanedPriceCoeffficients = cleanPriceCoeffficients(priceCoefficient);
    const cleanedGuestPriceCoeffficients = cleanPriceCoeffficients(guestPriceCoefficient);
    const cleanedPricingRegions = cleanPricingRegions(pricingRegion);
    const cleanedShortBreaks = cleanShortBreaks(shortBreaks);
    const cleanedCalendars = cleanCalendars(calendars);
    const cleanedCommissionRate = cleanCommissionRate(commissionRate);
    const cleanedMarkupHomeInsurances = cleanMarkupHomeInsurance(markupHomeInsurances);
    const cleanedMarkupSeasonalExtras = cleanMarkupSeasonalExtras(markupSeasonalExtras);
    const cleanedmarkupCurrencyFees = cleanMarkupCurrencyFee(markupCurrencyFees);
    const cleanedmarkupData = cleanMarkupData(markupData);

    yield all([
      call([appDB.upstreamProperties, appDB.upstreamProperties.bulkPut], cleanedProperties),
      call([appDB.sectors, appDB.sectors.bulkPut], cleanedSectors),
      call(
        [appDB.occupancyCoefficients, appDB.occupancyCoefficients.bulkPut],
        cleanedOccupancyCoefficients,
      ),
      call([appDB.features, appDB.features.bulkPut], features),
      call([appDB.bookingPerMonth, appDB.bookingPerMonth.bulkPut], cleanedBookingPerMonth),
      call(
        [appDB.bookingWindowPenalty, appDB.bookingWindowPenalty.bulkPut],
        cleanedBookingWindowPenalties,
      ),
      call([appDB.weekMapping, appDB.weekMapping.bulkPut], weekMapping),
      call([appDB.season, appDB.season.bulkPut], season),
      call([appDB.priceCoefficient, appDB.priceCoefficient.bulkPut], cleanedPriceCoeffficients),
      call(
        [appDB.guestPriceCoefficient, appDB.guestPriceCoefficient.bulkPut],
        cleanedGuestPriceCoeffficients,
      ),
      call(
        [appDB.bookingProbability, appDB.bookingProbability.bulkPut],
        cleanedBookingProbabilities,
      ),
      call([appDB.pricingRegion, appDB.pricingRegion.bulkPut], cleanedPricingRegions),
      call([appDB.shortBreak, appDB.shortBreak.bulkPut], cleanedShortBreaks),
      call([appDB.calendar, appDB.calendar.bulkPut], cleanedCalendars),
      call([appDB.countryRegions, appDB.countryRegions.bulkPut], countryRegions),
      call([appDB.commissionRate, appDB.commissionRate.bulkPut], cleanedCommissionRate),
      call(
        [appDB.markupHomeInsurance, appDB.markupHomeInsurance.bulkPut],
        cleanedMarkupHomeInsurances,
      ),
      call(
        [appDB.markupSeasonalExtras, appDB.markupSeasonalExtras.bulkPut],
        cleanedMarkupSeasonalExtras,
      ),
      call([appDB.areaCode, appDB.areaCode.bulkPut], areaCodes),
      call([appDB.cascadeTypes, appDB.cascadeTypes.bulkPut], cascadeTypes),
      call([appDB.cascadeData, appDB.cascadeData.bulkPut], cascadeData),
      call([appDB.currencies, appDB.currencies.bulkPut], cleanedCurrencies),
      call([appDB.priceIdMappings, appDB.priceIdMappings.bulkPut], priceIdMappings),
      call([appDB.markupCurrencyFee, appDB.markupCurrencyFee.bulkPut], cleanedmarkupCurrencyFees),
      call([appDB.markupData, appDB.markupData.bulkPut], cleanedmarkupData),
      call([appDB.markupMappings, appDB.markupMappings.bulkPut], markupMappings),
      call([appDB.countries, appDB.countries.bulkPut], cleanedCountries),
    ]);

    yield put(upstreamData.success({}));

    updateDataVersionNumber(dataVersionInfo.data_version_number);
  } catch (error) {
    yield put(upstreamData.failure({ errorMessage: (error as Error).message }));
  }
}

function* storeDefaultsSaga() {
  const currencies: ICurrency[] = yield call(
    // @ts-ignore-next-line
    [appDB.currencies, appDB.currencies.toArray],
  );
  const siteCurrency = currencies.find(({ isDefaultForSite }) => isDefaultForSite);
  const euroCurrency = currencies.find(({ code }) => code === EURO_CODE);

  if (!siteCurrency) {
    throw new Error('Could not find site currency.');
  }
  if (!euroCurrency) {
    throw new Error('Could not find euro currency.');
  }

  yield put(setEngineCurrency(siteCurrency));
  yield put(setEuroCurrency(euroCurrency));

  logCurrency(`$$$$$ Default site currency: ${siteCurrency.name}`, siteCurrency);
  logCurrency('€€€€€ Euro currency', euroCurrency);
}

export default function* upstreamDataSagas() {
  yield takeEvery(getType(upstreamData.request), updateUpstreamDataSaga);
  yield takeEvery(
    [getType(upstreamData.success), getType(upstreamData.failure)],
    storeDefaultsSaga,
  );
  yield takeEvery(getType(upstreamData.request), getCurrentUserSaga);
}
const cleanPropertyData = (properties: IUpstreamProperty[]) =>
  properties.map((property: any) => ({
    ...property,
    bathroomsBedroomsRatio: parseFloat(property.bathroomsBedroomsRatio),
    commercialOccupancy: parseFloat(property.commercialOccupancy),
    gradeNormalized: parseFloat(property.gradeNormalized),
    hottubPrivacy: parseFloat(property.hottubPrivacy),
    milesFromSeaClusterPercentage: parseFloat(property.milesFromSeaClusterPercentage),
    sleepsPerBedrooms: parseFloat(property.sleepsPerBedrooms),
    swimmingPool: parseFloat(property.swimmingPool),
    novasolSeasonAGuestPrice: parseFloat(property.novasolSeasonAGuestPrice),
    featureFactors: property.featureFactors.map((featureFactor: any) => ({
      featureName: featureFactor.feature,
      value: parseFloat(featureFactor.factor),
    })),
    realizedPrices: property.realizedPrices.map((realizedPrice: any) => ({
      ...realizedPrice,
      price: parseFloat(realizedPrice.price),
      weekendRatio: parseFloat(realizedPrice.weekendRatio),
      midweekRatio: parseFloat(realizedPrice.midweekRatio),
    })),
  }));

const cleanSectors = (sectors: any): ISector[] =>
  sectors.map((sector: any) => ({
    ...sector,
    latitude: parseFloat(sector.latitude),
    longitude: parseFloat(sector.longitude),
  }));

const cleanOccupancyCoefficients = (occupancyCoefficients: any): IOccupancyCoefficient[] =>
  occupancyCoefficients.map((occupancyCoefficient: any) => ({
    ...occupancyCoefficient,
    value: parseFloat(occupancyCoefficient.coefficient),
  }));

const cleanBookingWindowPenalties = (bookingWindowPenalties: any): IBookingWindowPenalty[] =>
  bookingWindowPenalties.map((bookingWindowPenalty: any) => ({
    ...bookingWindowPenalty,
    penalty: parseFloat(bookingWindowPenalty.penalty),
  }));

const cleanBookingProbability = (bookingProbabilities: any): IBookingProbability[] =>
  bookingProbabilities.map((bookingProbability: any) => ({
    ...bookingProbability,
    bookingProbability: parseFloat(bookingProbability.bookingProbability),
  }));

const cleanBookingPerMonth = (bookingPerMonths: any): IBookingPerMonth[] =>
  bookingPerMonths.map((bookingPerMonth: any) => ({
    ...bookingPerMonth,
    percentageOfNights: parseFloat(bookingPerMonth.percentageOfNights),
  }));

const cleanPriceCoeffficients = (priceCoefficients: any): IPriceCoefficient[] =>
  priceCoefficients.map((priceCoefficient: any) => ({
    ...priceCoefficient,
    coefficient: parseFloat(priceCoefficient.coefficient),
  }));

const cleanPricingRegions = (pricingRegions: any): IPricingRegion[] =>
  pricingRegions.map((pricingRegion: any) => ({
    ...pricingRegion,
    discount: parseFloat(pricingRegion.discount),
    lengthOfStay: parseFloat(pricingRegion.lengthOfStay),
  }));

const cleanShortBreaks = (shortBreaks: any): IShortBreakArrivalDistribution[] =>
  shortBreaks.map((region: any) => ({
    ...region,
    factor: parseFloat(region.factor),
  }));

const cleanCountries = (countries: any): ICountry[] =>
  countries.map((country: any) => ({
    ...country,
    taxRate: parseFloat(country.taxRate),
  }));

const cleanCalendars = (calendars: any): ICalendar[] =>
  calendars.map((calendar: any) => ({
    ...calendar,
    elasticity: parseFloat(calendar.elasticity),
    distribution: calendar.distribution.map((percentage: any) => ({
      ...percentage,
      value: parseFloat(percentage.value),
    })),
  }));

const cleanCommissionRate = (commissionRates: any): ICommissionRate[] =>
  commissionRates.map((commissionRate: any) => ({
    ...commissionRate,
    value: parseFloat(commissionRate.value),
    minValue: parseFloat(commissionRate.minValue),
  }));

const cleanMarkupHomeInsurance = (markupHomeInsurances: any): IMarkupHomeInsurance[] =>
  markupHomeInsurances.map((markupHomeinsurance: any) => ({
    ...markupHomeinsurance,
    price: parseFloat(markupHomeinsurance.price),
  }));

const cleanMarkupSeasonalExtras = (markupSeasonalExtras: any): IMarkupSeasonalExtras[] =>
  markupSeasonalExtras.map((markupSeasonalExtra: any) => ({
    ...markupSeasonalExtra,
    cancellationInsurancePercentage: parseFloat(
      markupSeasonalExtra.cancellationInsurancePercentage,
    ),
    minorDamages: parseFloat(markupSeasonalExtra.minorDamages),
  }));

const cleanCurrency = (currencies: any): ICurrency[] =>
  currencies.map((currency: any) => ({
    ...currency,
    conversionRateToDefault: parseFloat(currency.conversionRateToDefault),
  }));

const cleanMarkupCurrencyFee = (markupCurrencyFees: any): ICurrencyFee[] =>
  markupCurrencyFees.map((markupCurrencyFee: any) => ({
    ...markupCurrencyFee,
    conversion: parseFloat(markupCurrencyFee.conversion),
  }));

const cleanMarkupData = (MarkupData: any): IMarkupData[] =>
  MarkupData.map((markupData: any) => ({
    ...markupData,
    markup: parseFloat(markupData.markup),
  }));
