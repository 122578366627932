import { createSelector } from 'reselect';
import { INewPropertyQuote } from 'services/appDatabase';
import {
  getAveragePriceChange,
  getFloorPrice,
  getMarkupResults,
  getOccupancyResults,
  getPricingResults,
  getProperties,
  getRevenueResults,
  getSeasonPrices,
  getShortBreakResults,
} from 'stateManagement/Engine/selectors';
import {
  computePropertyFeatureFactors,
  getNewProperty,
  getQuoteCommissionRates,
} from 'stateManagement/Quote/selectors';
import { RootState } from 'stateManagement/types';

const getBenchmarkPropertiesForBackend = createSelector(getProperties, (properties) =>
  properties.map((property) => property.uuid),
);

export const getNewPropertyQuote = createSelector(
  getNewProperty,
  computePropertyFeatureFactors,
  getBenchmarkPropertiesForBackend,
  getOccupancyResults,
  getRevenueResults,
  getPricingResults,
  getSeasonPrices,
  getAveragePriceChange,
  getShortBreakResults,
  getQuoteCommissionRates,
  getFloorPrice,
  getMarkupResults,
  (
    quote,
    featureFactors,
    benchmarkProperties,
    occupancies,
    revenues,
    pricingResults,
    newSeasonPrices,
    apcResults,
    shortBreakResults,
    commissionRates,
    floorPrice,
    markups,
  ): INewPropertyQuote => ({
    ...quote,
    firstAvailable: quote.firstAvailable.toISODate(),
    goLive: quote.goLive.toISODate(),
    availabilityOpenDate: quote.availabilityOpenDate || null,
    availabilityCloseDate: quote.availabilityCloseDate || null,
    grade: quote.grade || 0,
    floorPrice,
    featureFactors,
    benchmarkProperties,
    occupancies,
    shortBreakResults,
    revenues,
    pricingResults,
    newSeasonPrices,
    apcResults,
    commissionRate: commissionRates.commissionRate,
    minCommissionRate: commissionRates.minCommissionRate,
    defaultCommissionRate: commissionRates.defaultCommissionRate,
    markups,
    salesforceUpdated: quote.salesforceUpdated || null,
  }),
);

export const getIsFetchingDownstream = (state: RootState) => state.downstream.fetching;
export const getIsFetchedDownstream = (state: RootState) => state.downstream.fetched;

export const getInFlightUpdates = (state: RootState) => state.downstream.inFlightUpdates;
