import { createSelector } from 'reselect';
import { FeatureValueSet, INewProperty } from 'services/appDatabase';
import { RootState } from 'stateManagement/types';
import { parseBrandFeatures } from './features';
import { QuoteState } from './reducer';

export const getRawQuote = (state: RootState): QuoteState => state.quote;

export const getQuoteName = (state: RootState) =>
  `${state.quote.firstName} ${state.quote.lastName}`;

export const getQuoteDate = (state: RootState) => {
  if (state.quote.updatedAt) {
    return new Date(state.quote.updatedAt).toLocaleDateString();
  }
  return new Date().toString();
};

export const getQuoteFeatures = createSelector(getRawQuote, (quote) => quote.features);
export const getQuoteUuid = createSelector(getRawQuote, (quote) => quote.uuid);

export const getFirstDateAvailable = createSelector(getRawQuote, (quote) => quote.firstAvailable);
export const getGoLiveDate = createSelector(getRawQuote, (quote) => quote.goLive);

// NOTE: cottages.com specific
export const computePropertyFeatureFactors = createSelector(
  getRawQuote,
  (quote: QuoteState): FeatureValueSet => parseBrandFeatures(quote),
);

export const getNewProperty = createSelector(
  getRawQuote,
  computePropertyFeatureFactors,
  (quote: QuoteState, features: FeatureValueSet): INewProperty => ({
    ...quote,
    featureFactors: features,
  }),
);

export const getOwnerWeeks = createSelector(getRawQuote, (quote) => quote.ownerWeeks);

export const getQuoteStatus = createSelector(getRawQuote, (quote) => quote.status);

export const getQuoteCommissionRate = createSelector(getRawQuote, (quote) => quote.commissionRate);
export const getQuoteMinCommissionRate = createSelector(getRawQuote, (quote) =>
  Number(quote.minCommissionRate),
);
export const getQuoteDefaultCommissionRate = createSelector(getRawQuote, (quote) =>
  Number(quote.defaultCommissionRate),
);

export const getQuoteCommissionRates = createSelector(getRawQuote, (quote) => ({
  commissionRate: quote.commissionRate,
  minCommissionRate: quote.minCommissionRate,
  defaultCommissionRate: quote.defaultCommissionRate,
}));

export const getUseGuestPrice = createSelector(getRawQuote, (quote) => quote.useGuestPrice);

export const getUseGuestPriceCoefficients = createSelector(
  getRawQuote,
  (quote) => quote.useGuestPriceCoefficients,
);

export const getDisableReasonDialog = createSelector(
  getRawQuote,
  (quote) => quote.disableReasonDialog,
);
