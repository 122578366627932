import { QuoteQueueStatus, QuoteStatus } from 'constants/quotes';
import { INewPropertyQuote } from 'services/appDatabase';
import { createAsyncAction, createStandardAction } from 'typesafe-actions';

export const dequeuePropertiesManualRequest = createStandardAction(
  'DEQUEUE_PROPERTIES_MANUAL/REQUEST',
)();

export const uploadNewProperty = createAsyncAction(
  'UPLOAD_NEW_PROPERTY/REQUEST',
  'UPLOAD_NEW_PROPERTY/SUCCESS',
  'UPLOAD_NEW_PROPERTY/FAILURE',
)<
  INewPropertyQuote,
  {
    uuid: string;
    persisted: boolean;
    status: QuoteStatus;
  },
  {
    uuid: string;
    errorMessage: string;
    queueStatus: QuoteQueueStatus;
  }
>();

export const downloadNewProperties = createAsyncAction(
  'DOWNLOAD_NEW_PROPERTIES/REQUEST',
  'DOWNLOAD_NEW_PROPERTIES/SUCCESS',
  'DOWNLOAD_NEW_PROPERTIES/FAILURE',
)<
  {},
  {},
  {
    errorMessage: string;
  }
>();
