import { createSelector } from 'reselect';
import { tokenHasExpired } from 'services/networking/token';
import { RootState } from 'stateManagement/types';

export const getUserToken = (state: RootState) => state.login.token;
export const getLoginError = (state: RootState) => state.login.loginError;

export const getIsLoggedIn = createSelector(getUserToken, (token) =>
  token ? !tokenHasExpired(token) : false,
);
