import { MARKUP_COUNTRY_FALLBACK } from 'constants/countries';
import _flatten from 'lodash/flattenDeep';
import _map from 'lodash/map';
import _round from 'lodash/round';
import { INewSeasonPriceForYear } from 'pages/Quote/Quote';
import appDatabase, {
  ICurrency,
  IMarkupSeasonalExtras,
  INewPropertyQuote,
  ISeasonalSummary,
} from 'services/appDatabase';
import { getYearSummary } from 'services/engine/novasol/pricing';
import { IOccupancyWeek, IWeekPricingData } from 'services/engine/types';
import { round } from 'services/utils/numberUtils';
import { AdjustedResult, AdjustedResultItem } from 'stateManagement/Engine';
import {
  getFilteredMarkupSeasonalExtras,
  getHomeInsuranceValues,
  getPriceMappingForCalendar,
} from '../../services/markup/markupCalculator.getters';
import { getCancellationInsurance } from '../../services/markup/markupCalculator.helpers';

export const formatQuote = async (newProperty: INewPropertyQuote, currency: ICurrency) => {
  return await formatQuoteNovasol(newProperty, currency);
};

const formatOccupancyWeeks = (occupancyWeeks: IOccupancyWeek[]) => {
  return occupancyWeeks.map((occupancyWeek) => ({
    ...occupancyWeek,
    season: occupancyWeek.season.name,
  }));
};

// Prepare the quote for our backend and crm
const formatQuoteNovasol = async (newProperty: INewPropertyQuote, currency: ICurrency) => {
  if (!newProperty.areaCode) {
    // @ts-ignore-next-line
    delete newProperty.areaCode;
  }

  const seasons = await appDatabase.season.toArray();
  const calendarRow = await appDatabase.calendar.get(newProperty.calendar);
  const cascadeRow = await appDatabase.cascadeTypes.get(newProperty.cascadeType);
  const country = await appDatabase.countries.toCollection().first();
  const useGuestPrice = country?.useGuestPrice || false;
  const countryCode = country?.code || '';
  const markupHomeInsurance = await appDatabase.markupHomeInsurance.toArray();
  const priceMappings = await appDatabase.priceIdMappings.toArray();
  const markupSeasonalExtras = await appDatabase.markupSeasonalExtras.toArray();

  // get all supporting data to recalculate the share
  const year = newProperty.firstAvailable.slice(0, 4); // converts 2020-01-01 to 2020
  const filteredPriceMapping = await getPriceMappingForCalendar(
    newProperty.calendar,
    priceMappings,
    year,
  );
  const filteredSeasonalExtras = getFilteredMarkupSeasonalExtras(
    countryCode,
    filteredPriceMapping,
    markupSeasonalExtras,
  );
  const filteredHomeInsurances = getHomeInsuranceValues(
    countryCode,
    MARKUP_COUNTRY_FALLBACK.INSURANCE,
    markupHomeInsurance.filter((i) => i.year === Number(year)),
  );

  // Add rounding to seasonalSummary and week prices
  const pricingResults = pricingResultsFrom(newProperty);

  const pricingResultsCrm = pricingResultsCRMFrom(newProperty, seasons);

  const occupancyCrm = formatAdjustments(newProperty.occupancies, (item) => ({
    items: item.data.map((x) => ({
      weeks: x.weeks ? formatOccupancyWeeks(x.weeks) : [],
      nightHigh: round(x.nights.highest, 4),
      nightLow: round(x.nights.lowest, 4),
      bookingHigh: round(x.bookings.highest, 4),
      bookingLow: round(x.bookings.lowest, 4),
    })),
  }));

  newProperty = convertSeasonPrices(newProperty, currency);

  // recalculate the share in owner currency if we have all the data
  if (newProperty.newSeasonPrices) {
    newProperty.commissionRate = recalculateShareInOwnerCurrency(
      newProperty.newSeasonPrices.yearOne as INewSeasonPriceForYear,
      countryCode,
      filteredSeasonalExtras['productMarket'],
      filteredHomeInsurances['productMarket'],
    );
  }

  // this also contains the data that will be send through to crm
  return {
    // For the django backend
    ...newProperty,
    salesforceUpdateErrors: JSON.stringify(newProperty.salesforceUpdateErrors),

    // data for being able to load a quote from the backend in the redux store
    rawQuoteState: JSON.stringify({
      ...newProperty,
      cascadeTypeName: cascadeRow ? cascadeRow.name : '', // crm needs the names instead of UUID
      calendarName: calendarRow ? calendarRow.name : '', // crm needs the names instead of UUID
      pricingResultsCrm, // Contains a striped down version of the prices with seasonNames
      occupancyCrm, // Contains a striped down version of the occupancies with seasonNames
      useGuestPrice, // Indicates if this is an N2S country (and if guest prices should be published to AS400)
    }),

    featureFactors: newProperty.featureFactors.map((ff) => ({
      feature: ff.featureName,
      factor: _round(ff.value, 2),
    })),
    occupancyResults: formatAdjustments(newProperty.occupancies, (item) => ({
      items: item.data.map((x) => ({
        ...x,
        nightHigh: round(x.nights.highest, 4),
        nightLow: round(x.nights.lowest, 4),
        bookingHigh: round(x.bookings.highest, 4),
        bookingLow: round(x.bookings.lowest, 4),
      })),
    })),
    // Engine results (for django backend and crm)
    revenues: newProperty.revenues.map((x) => ({
      ...x,
      highest: round(x.highest, 2),
      lowest: round(x.lowest, 2),
    })),
    shortbreakResults: [],
    ownerWeeks: newProperty.ownerWeeks,
    commissionRate: round(newProperty.commissionRate, 2),
    shortbreakWeeklyRatio: [],
    pricingResults: _flatten(pricingResults),
  };
};

const formatAdjustments = <T>(
  adjustedResult: AdjustedResult<T>,
  extra: (adjustedItem: AdjustedResultItem<T>) => any,
) => {
  return _map(adjustedResult as any, (adjustedItem: AdjustedResultItem<T>, adjustmentType) => ({
    adjustmentType: adjustmentType.toUpperCase(),
    reason: adjustedItem.reason,
    note: adjustedItem.note,
    adjustmentReasons: adjustedItem.adjustmentReasons,
    ...extra(adjustedItem),
  }));
};

export function pricingResultsFrom(newProperty: INewPropertyQuote) {
  return formatAdjustments(newProperty.pricingResults, (item) => ({ item })).map((line) =>
    line.item.data.map((pricing: any) => {
      const seasonalSummary: ISeasonalSummary[] = [];
      const yearSummary = getYearSummary(pricing.yearData);
      yearSummary.forEach((seasonPrice, key) => {
        seasonalSummary.push({
          season: key,
          high: round(seasonPrice.highest, 0),
          low: round(seasonPrice.lowest, 0),
        });
      });

      const weekPricings = pricing.yearData.map((weekPrice: any) => ({
        weekNumber: weekPrice.weekNumber,
        price: round(weekPrice.price, 0),
      }));

      return {
        ...line,
        year: pricing.year,
        weekPricings,
        seasonalSummary,
      };
    }),
  );
}

export function pricingResultsCRMFrom(newProperty: INewPropertyQuote, seasons: any[]) {
  return formatAdjustments(newProperty.pricingResults, (item) => ({ item })).map((line) =>
    line.item.data.map((pricing: any) => {
      const weekPricings = pricing.yearData.map((weekPrice: IWeekPricingData) => {
        const season = seasons.find((s) => s.uuid === weekPrice.season);
        return {
          weekNumber: weekPrice.weekNumber,
          price: round(weekPrice.price, 0),
          salesPrice: round(weekPrice.salesPrice ?? 0, 0),
          seasonName: season ? season.name : '',
          open: weekPrice.open,
        };
      });

      return {
        adjustmentType: line.adjustmentType,
        reason: line.reason,
        note: line.note,
        adjustmentReasons: line.adjustmentReasons,
        year: pricing.year,
        weekPricings,
      };
    }),
  );
}

export function convertSeasonPrices(newProperty: INewPropertyQuote, currency: ICurrency) {
  const currencyConversion = currency.conversionRateToDefault;

  const sPrices = newProperty.seasonPrices;

  if (sPrices) {
    Object.keys(sPrices.yearOne).forEach((season: any) => {
      sPrices.yearOne[season] = {
        value: (sPrices.yearOne[season] as number) * currencyConversion,
        isocodecurrency: currency.code,
      };
    });

    Object.keys(sPrices.yearTwo).forEach((season: any) => {
      sPrices.yearTwo[season] = {
        value: (sPrices.yearTwo[season] as number) * currencyConversion,
        isocodecurrency: currency.code,
      };
    });
  }

  return newProperty;
}

export const recalculateShareInOwnerCurrency = (
  seasonPrices: INewSeasonPriceForYear,
  country: string,
  markupSeasonalExtras: IMarkupSeasonalExtras[],
  markupHomeInsurance: number,
): number => {
  const seasonalExtrasForCurrentSeason = markupSeasonalExtras.find(
    (markupExtras) => markupExtras.season === 'A',
  );
  if (!seasonalExtrasForCurrentSeason) {
    return 0;
  }
  const cancXInsurance = getCancellationInsurance(seasonalExtrasForCurrentSeason);
  const minorDamages = seasonalExtrasForCurrentSeason.minorDamages;
  const salesPrice = seasonPrices.A.salesValue ?? 0;
  const ownerPrice = seasonPrices.A.value;

  const calculation = country === 'DK' ? danishShareCalculation : restOfEuropeShareCalculation;
  const share = calculation({
    salesPrice,
    ownerPrice,
    minorDamages,
    cancXInsurance,
    homeInsurance: markupHomeInsurance,
  });

  return round(share, 6);
};

type shareCalculationProps = {
  salesPrice: number;
  ownerPrice: number;
  minorDamages: number;
  cancXInsurance: number;
  homeInsurance: number;
};

const danishShareCalculation = (prices: shareCalculationProps): number => {
  const { salesPrice, ownerPrice, minorDamages, cancXInsurance, homeInsurance } = prices;
  const markup = (salesPrice - minorDamages) / (ownerPrice + homeInsurance) / cancXInsurance;
  const share = 1 - 1 / markup;

  return share;
};

const restOfEuropeShareCalculation = (prices: shareCalculationProps): number => {
  const { salesPrice, ownerPrice, minorDamages, cancXInsurance, homeInsurance } = prices;

  const markup = (salesPrice - (homeInsurance + minorDamages)) / ownerPrice / cancXInsurance;
  const share = 1 - 1 / markup;

  return share;
};
