import { PROPERTY_TYPE_UPSTREAM_VALUES } from 'constants/propertyTypes';
import { DateTime } from 'luxon';
import { AC_OPTIONS } from 'molecules/featureComponents/novasol/AC';
import { BALCONY_TYPES } from 'molecules/featureComponents/novasol/Balcony';
import { DC_OPTIONS } from 'molecules/featureComponents/novasol/DiscountConcepts';
import { ELECTRICITY_TYPES } from 'molecules/featureComponents/novasol/Electricity';
import { FISH_HOUSE_TYPES } from 'molecules/featureComponents/novasol/Fishhouse';
import { GERMAN_TV_OPTIONS } from 'molecules/featureComponents/novasol/GermanTv';
import { INTERNET_OPTIONS } from 'molecules/featureComponents/novasol/Internet';
import { PLOT_TYPES } from 'molecules/featureComponents/novasol/PlotType';
import { POOL_TYPES } from 'molecules/featureComponents/novasol/Pooltype';
import { SAUNA_TYPES } from 'molecules/featureComponents/novasol/Sauna';
import { VIEW_TYPES } from 'molecules/featureComponents/novasol/Viewtype';
import { WHIRLPOOL_TYPES } from 'molecules/featureComponents/novasol/Whirlpool';
import { FeatureValueSet } from 'services/appDatabase';
import { getPropertyTypeValue } from 'services/form/propertyTypes';
import { QuoteState } from './reducer';

export interface NovasolFeatures {
  isAllInclusive: boolean;
  finalCleaning: number;
  isPetFriendly: boolean;
  isActivityHouse: boolean;
  internet: number;
  sauna: number;
  fishhouse: string;
  ac: string;
  discountConcept: string;
  germanTv: number;
  viewtype: number;
  pooltype: number;
  whirlpool: number;
  buildYear: number | undefined;
  propertySize: number | undefined;
  plotSize: number | undefined;
  distanceToSea: number | undefined;
  renovationYear: number | undefined;
  shortBreakNovasol: boolean;
  balcony: number | undefined;
  plotType: number | undefined;
  energy: number | undefined;
  ownerOnPremise: boolean;
  bedrooms: number;
  bathrooms: number;
  isFullyFlexible: boolean;
}

export type IBrandFeatures = NovasolFeatures;

const bool2int = (b: boolean): number => +b;

export const parseBrandFeatures = (quote: QuoteState): FeatureValueSet => parseNovasol(quote);

const getDistanceToSea = (distance: number | undefined) => {
  if (distance === 0) {
    return 0;
  }
  if (!distance) {
    // put distanceToSea into the largest bucket if it is not entered
    return 2000;
  }
  return distance;
};

const parseNovasol = (quote: QuoteState): FeatureValueSet => {
  const currentYear = DateTime.local().year;
  const buildYearDifference = quote.features.buildYear ? currentYear - quote.features.buildYear : 0;
  const renovationYearDifference = quote.features.renovationYear
    ? currentYear - quote.features.renovationYear
    : undefined;

  const propertySize = quote.features.propertySize || 0;
  const plotSize = quote.features.plotSize || 0;
  const distanceToSea = getDistanceToSea(quote.features.distanceToSea);

  return [
    {
      featureName: 'property_type_nameholiday apartment',
      value: bool2int(
        getPropertyTypeValue(quote.propertyType) ===
          PROPERTY_TYPE_UPSTREAM_VALUES.holidayApartement.value,
      ),
    },
    {
      featureName: 'property_type_nameholiday house',
      value: bool2int(
        getPropertyTypeValue(quote.propertyType) ===
          PROPERTY_TYPE_UPSTREAM_VALUES.holidayHouse.value,
      ),
    },
    {
      featureName: 'property_type_namesemi detached house',
      value: bool2int(
        getPropertyTypeValue(quote.propertyType) ===
          PROPERTY_TYPE_UPSTREAM_VALUES.semiDetachedHouse.value,
      ),
    },
    {
      featureName: 'pool_type_nameno_pool',
      value: bool2int(
        [
          POOL_TYPES.NONE,
          POOL_TYPES.WATERPARK_ENTRANCE,
          POOL_TYPES.WATERPARK_POOL_ACCESS,
          POOL_TYPES.WATERPARK_POOL_ACCESS_OUTDOOR,
          POOL_TYPES.PADDLING,
        ].includes(quote.features.pooltype),
      ),
    },
    {
      featureName: 'pool_type_nameindoor_swimming_pool',
      value: bool2int(
        [POOL_TYPES.SHARED_INDOOR, POOL_TYPES.INDOOR].includes(quote.features.pooltype),
      ),
    },
    {
      featureName: 'pool_type_nameoutdoor_swimming_pool',
      value: bool2int(
        [POOL_TYPES.OUTDOOR, POOL_TYPES.COUNTERCURRENT].includes(quote.features.pooltype),
      ),
    },
    {
      featureName: 'pool_type_nameshared_outdoor_swimming_pool',
      value: bool2int(quote.features.pooltype === POOL_TYPES.SHARED_OUTDOOR),
    },
    {
      featureName: 'bedrooms_group0-1',
      value: bool2int(quote.bedrooms === 0 || quote.bedrooms === 1),
    },
    { featureName: 'bedrooms_group2', value: bool2int(quote.bedrooms === 2) },
    { featureName: 'bedrooms_group3', value: bool2int(quote.bedrooms === 3) },
    { featureName: 'bedrooms_group4', value: bool2int(quote.bedrooms === 4) },
    { featureName: 'bedrooms_group5', value: bool2int(quote.bedrooms === 5) },
    { featureName: 'bedrooms_group6', value: bool2int(quote.bedrooms === 6) },
    { featureName: 'bedrooms_group7+', value: bool2int(quote.bedrooms >= 7) },
    {
      featureName: 'no_baths_per_bedroom',
      value: quote.bedrooms === 0 ? quote.bathrooms : quote.bathrooms / quote.bedrooms,
    },
    {
      featureName: 'plot_size_0',
      value: bool2int(plotSize === 0),
    },
    {
      featureName: 'plot_size_1-250',
      value: bool2int(plotSize > 0 && plotSize <= 250),
    },
    {
      featureName: 'plot_size_251-500',
      value: bool2int(plotSize > 250 && plotSize <= 500),
    },
    {
      featureName: 'plot_size_501-1000',
      value: bool2int(plotSize > 500 && plotSize <= 1000),
    },
    {
      featureName: 'plot_size_1001-2000',
      value: bool2int(plotSize > 1000 && plotSize <= 2000),
    },
    {
      featureName: 'plot_size_2001-5000',
      value: bool2int(plotSize > 2000 && plotSize <= 5000),
    },
    { featureName: 'plot_size_above_5000', value: bool2int(plotSize > 5000) },
    // {
    //   featureName: 'max_persons_per_bedroom',
    //   value: quote.bedrooms === 0 ? quote.guests : quote.guests / quote.bedrooms,
    // },
    // TODO: remove parseFloat once we put validation on the input fields and change their type to number
    {
      featureName: 'size_of_property_per_bedroom',
      value: quote.bedrooms === 0 ? propertySize : propertySize / quote.bedrooms,
    },
    { featureName: 'stars_group0-2', value: bool2int(quote.grade <= 2) },
    { featureName: 'stars_group3', value: bool2int(quote.grade === 3) },
    { featureName: 'stars_group4', value: bool2int(quote.grade === 4) },
    { featureName: 'stars_group5', value: bool2int(quote.grade === 5) },
    { featureName: 'build_year_grouplast_00to03', value: bool2int(buildYearDifference <= 3) },
    {
      featureName: 'build_year_grouplast_04to10',
      value: bool2int(buildYearDifference >= 4 && buildYearDifference <= 10),
    },
    {
      featureName: 'build_year_grouplast_11to15',
      value: bool2int(buildYearDifference >= 11 && buildYearDifference <= 15),
    },
    { featureName: 'build_year_grouplast_16+', value: bool2int(buildYearDifference >= 16) },
    {
      featureName: 'renovation_year_adj_grouplast_00to03',
      value: bool2int(renovationYearDifference! <= 3),
    },
    {
      featureName: 'renovation_year_adj_grouplast_04to10',
      value: bool2int(renovationYearDifference! >= 4 && renovationYearDifference! <= 10),
    },
    {
      featureName: 'renovation_year_adj_grouplast_11to15',
      value: bool2int(renovationYearDifference! >= 11 && renovationYearDifference! <= 15),
    },
    {
      featureName: 'renovation_year_adj_grouplast_16+',
      value: bool2int(renovationYearDifference! >= 16),
    },
    {
      featureName: 'renovation_year_adj_groupN/A',
      value: bool2int(!quote.features.renovationYear),
    },
    { featureName: 'renovated_last_10_years', value: bool2int(renovationYearDifference! <= 10) },
    {
      featureName: 'distance_to_sea_in_km_buckets<=0.3',
      value: bool2int(distanceToSea <= 300),
    },
    {
      featureName: 'distance_to_sea_in_km_buckets0.3-1.5',
      value: bool2int(distanceToSea > 300 && distanceToSea <= 1500),
    },
    {
      featureName: 'distance_to_sea_in_km_buckets>1.5',
      value: bool2int(distanceToSea > 1500),
    },
    {
      featureName: 'view_type_nameno_sea_view',
      value: bool2int(
        [VIEW_TYPES.NONE, VIEW_TYPES.DUNES, VIEW_TYPES.INLET].includes(quote.features.viewtype),
      ),
    },
    {
      featureName: 'view_type_namerestricted_seaview',
      value: bool2int(
        [
          VIEW_TYPES.RESTRICTED_SEA_VIEW,
          VIEW_TYPES.SEA_VIEW_FIRST,
          VIEW_TYPES.SEA_VIEW_TERRACE,
          VIEW_TYPES.SEA_VIEW_PLOT,
        ].includes(quote.features.viewtype),
      ),
    },
    {
      featureName: 'view_type_namesea_view',
      value: bool2int(
        [VIEW_TYPES.SEA_VIEW, VIEW_TYPES.PANORAMIC_WATER].includes(quote.features.viewtype),
      ),
    },
    {
      featureName: 'water_park_y_n',
      value: bool2int(
        [
          POOL_TYPES.WATERPARK_ENTRANCE,
          POOL_TYPES.WATERPARK_POOL_ACCESS,
          POOL_TYPES.WATERPARK_POOL_ACCESS_OUTDOOR,
        ].includes(quote.features.pooltype),
      ),
    },
    { featureName: 'sauna_y_n', value: bool2int(quote.features.sauna !== SAUNA_TYPES.NONE) },
    {
      featureName: 'whirlpool_type_y_n',
      value: bool2int(quote.features.whirlpool !== WHIRLPOOL_TYPES.NONE),
    },
    { featureName: 'pets_allowed', value: bool2int(quote.features.isPetFriendly) },
    {
      featureName: 'german_tv_channels_y_n',
      value: bool2int(
        [
          GERMAN_TV_OPTIONS.SATELLITE_GERMAN,
          GERMAN_TV_OPTIONS.SATELLITE_GERMAN_SCAND,
          GERMAN_TV_OPTIONS.CABLE_GERMAN,
          GERMAN_TV_OPTIONS.CABLE_GERMAN_SCAND,
          GERMAN_TV_OPTIONS.GERMAN,
        ].includes(quote.features.germanTv),
      ),
    },
    { featureName: 'wifi_y_n', value: bool2int(quote.features.internet !== INTERNET_OPTIONS.NONE) },
    { featureName: 'all_inclusive_y_n', value: bool2int(quote.features.isAllInclusive) },
    { featureName: 'activity_house_y_n', value: bool2int(quote.features.isActivityHouse) },
    {
      featureName: 'fish_house_combined_y_n',
      value: bool2int(quote.features.fishhouse !== FISH_HOUSE_TYPES.NONE),
    },
    { featureName: 'ac_y_n', value: bool2int(quote.features.ac !== AC_OPTIONS.NONE) },
    // { featureName: 'final_cleaning_y_n', value: bool2int(quote.features.finalCleaning) },
    { featureName: 'shortbreaks_allowed', value: bool2int(quote.features.shortBreakNovasol) },
    { featureName: 'is_fully_flexible', value: bool2int(quote.features.isFullyFlexible) },
    {
      featureName: 'terrace_balcony_',
      value: bool2int(quote.features.balcony === BALCONY_TYPES.NONE),
    },
    {
      featureName: 'terrace_balcony_open_terrace',
      value: bool2int(quote.features.balcony === BALCONY_TYPES.OPEN_TERRACE),
    },
    {
      featureName: 'terrace_balcony_covered_terrace',
      value: bool2int(quote.features.balcony === BALCONY_TYPES.COVERED_TERRACE),
    },
    {
      featureName: 'terrace_balcony_open_and_covered_terrace',
      value: bool2int(quote.features.balcony === BALCONY_TYPES.OPEN_COVERED_TERRACE),
    },
    {
      featureName: 'terrace_balcony_balcony_and_terrace',
      value: bool2int(quote.features.balcony === BALCONY_TYPES.BALCONY_TERRACE),
    },
    {
      featureName: 'terrace_balcony_balcony',
      value: bool2int(quote.features.balcony === BALCONY_TYPES.BALCONY),
    },
    {
      featureName: 'terrace_balcony_porch',
      value: bool2int(quote.features.balcony === BALCONY_TYPES.PORCH),
    },
    {
      featureName: 'terrace_balcony_partly_covered_terrace',
      value: bool2int(quote.features.balcony === BALCONY_TYPES.PARTLY_COVERED_TERRACE),
    },
    {
      featureName: 'terrace_balcony_shared_terracebalcony',
      value: bool2int(quote.features.balcony === BALCONY_TYPES.SHARED_TERRACE_BALCONY),
    },
    {
      featureName: 'terrace_balcony_terracebalcony',
      value: bool2int(quote.features.balcony === BALCONY_TYPES.TERRACE_BALCONY),
    },
    {
      featureName: 'plot_type_',
      value: bool2int(quote.features.plotType === PLOT_TYPES.NONE),
    },
    {
      featureName: 'plot_type_fenced_garden',
      value: bool2int(quote.features.plotType === PLOT_TYPES.FENCED_GARDEN),
    },
    {
      featureName: 'plot_type_private_garden',
      value: bool2int(quote.features.plotType === PLOT_TYPES.PRIVATE_GARDEN),
    },
    {
      featureName: 'plot_type_private_fenced_garden',
      value: bool2int(quote.features.plotType === PLOT_TYPES.PRIVATE_FENCED_GARDEN),
    },
    {
      featureName: 'plot_type_open_site',
      value: bool2int(quote.features.plotType === PLOT_TYPES.OPEN_SITE),
    },
    {
      featureName: 'plot_type_fenced_plot',
      value: bool2int(quote.features.plotType === PLOT_TYPES.FENCED_PLOT),
    },
    {
      featureName: 'plot_type_secluded_plot',
      value: bool2int(quote.features.plotType === PLOT_TYPES.SECLUDED_PLOT),
    },
    {
      featureName: 'energy_costs_incl_',
      value: bool2int(quote.features.energy === ELECTRICITY_TYPES.NONE),
    },
    {
      featureName: 'energy_costs_incl_electri_inkl_in_week_no_1939',
      value: bool2int(quote.features.energy === ELECTRICITY_TYPES.INCLUDED_19_39),
    },
    {
      featureName: 'energy_costs_incl_electricity_included',
      value: bool2int(quote.features.energy === ELECTRICITY_TYPES.INCLUDED),
    },
    {
      featureName: 'energy_costs_incl_electri_inkl_in_week_no_2335',
      value: bool2int(quote.features.energy === ELECTRICITY_TYPES.INCLUDED_23_35),
    },
    {
      featureName: 'energy_costs_incl_electricity_and_water_incl',
      value: bool2int(quote.features.energy === ELECTRICITY_TYPES.ELEC_WATER_INCL),
    },
    // Note there is a different in name in frontend vs backend for this field
    // Ideally we'd do a fresh data export / import and update the name to
    // energy_costs_water_included
    {
      featureName: 'energy_costs_incl_electricity_incl_during_summer',
      value: bool2int(quote.features.energy === ELECTRICITY_TYPES.WATER_INCLUDED),
    },
    {
      featureName: 'energy_costs_incl_heating_incl',
      value: bool2int(quote.features.energy === ELECTRICITY_TYPES.HEATING_INCL),
    },
    {
      featureName: 'energy_costs_incl_consumption_costs_incl',
      value: bool2int(quote.features.energy === ELECTRICITY_TYPES.CONSUMPTION_INCL),
    },
    {
      featureName: 'owner_on_premise_y_n',
      value: bool2int(quote.features.ownerOnPremise),
    },
    {
      featureName: 'discount_concepts_',
      value: bool2int(quote.features.discountConcept === DC_OPTIONS.NONE),
    },
    {
      featureName: 'discount_concepts_spar',
      value: bool2int(quote.features.discountConcept === DC_OPTIONS.SPAR),
    },
    {
      featureName: 'discount_concepts_longterm_stay',
      value: bool2int(quote.features.discountConcept === DC_OPTIONS.LONG_TERM),
    },
  ];
};
