import { QuoteQueueStatus, QuoteStatus } from 'constants/quotes';
import { INewPropertyQuote } from 'services/appDatabase';
import { initialQuoteState } from 'stateManagement/Quote';

const rejectedBySF = (updateErrors: any[], updated: string) => {
  return (
    updateErrors &&
    updateErrors.length > 0 &&
    updated &&
    updateErrors.find((e: any) => e.statusCode >= 400 && e.statusCode < 500)
  );
};

const failedToSubmitToSF = (updateErrors: any[], updated: string) => {
  return updateErrors && updateErrors.length >= 120 && updated;
};

export const determineQuoteState = (property: any): QuoteStatus => {
  if (
    !property.occupancyResults ||
    rejectedBySF(property.salesforceUpdateErrors, property.salesforceUpdated) ||
    failedToSubmitToSF(property.salesforceUpdateErrors, property.salesforceUpdated)
  ) {
    return QuoteStatus.POPULATING;
  } else if (property.salesforceUpdated) {
    return QuoteStatus.SUBMITTED;
  } else {
    return QuoteStatus.DRAFT;
  }
};

export const cleanNewProperty = (property: any): INewPropertyQuote => {
  const rawQuoteState = JSON.parse(property.rawQuoteState);

  const additionalPropertyState: Partial<INewPropertyQuote> = {
    latitude: parseFloat(property.latitude),
    longitude: parseFloat(property.longitude),
    queueStatus: QuoteQueueStatus.UPLOADED,
    errorMessage: null,
    persisted: property.persisted,
    econtractId: property.econtractId,
  };

  // If the quote does not have engine result, set the status to populating
  // This way it does not try to sync to the backend until the engine has given resutls

  additionalPropertyState.status = determineQuoteState(property);

  // quote details
  additionalPropertyState.uuid = property.uuid;
  additionalPropertyState.contractNumber = property.contractNumber;
  additionalPropertyState.econtractId = property.econtractId;
  additionalPropertyState.leadId = property.leadId;
  additionalPropertyState.updatedAt = property.updatedAt;

  // quote characteristics
  additionalPropertyState.features = {
    ...initialQuoteState.features,
    ...rawQuoteState.features,
  };
  additionalPropertyState.postcode = property.postcode;
  additionalPropertyState.subRegion = property.subRegion;
  additionalPropertyState.countryRegion = property.countryRegion;
  additionalPropertyState.propertyType = property.propertyType;
  additionalPropertyState.houseNumber = property.houseNumber;
  additionalPropertyState.addressLine1 = property.addressLine1;
  additionalPropertyState.addressLine2 = property.addressLine2;
  additionalPropertyState.areaCode = property.areaCode;
  additionalPropertyState.city = property.city;

  additionalPropertyState.bathrooms = property.bathrooms;
  additionalPropertyState.bedrooms = property.bedrooms;
  additionalPropertyState.guests = property.guests;
  additionalPropertyState.grade = property.grade;

  additionalPropertyState.firstName = property.firstName;
  additionalPropertyState.lastName = property.lastName;
  additionalPropertyState.salesforceUpdated = property.salesforceUpdated;
  additionalPropertyState.salesforceUpdateErrors = property.salesforceUpdateErrors;

  return {
    ...initialQuoteState,
    ...rawQuoteState,
    ...additionalPropertyState,
  };
};

export const cleanNewProperties = (rawProperties: any): INewPropertyQuote[] =>
  rawProperties.map(cleanNewProperty);
