import { ActionType, getType } from 'typesafe-actions';

import { AnyAction } from 'redux';
import { dataUpdateAvailable, fetchFeatureFlags, updateAvailable } from './actions';

export type ConfigurationAction = ActionType<
  | typeof fetchFeatureFlags.request
  | typeof fetchFeatureFlags.success
  | typeof fetchFeatureFlags.failure
  | typeof updateAvailable.request
  | typeof updateAvailable.success
  | typeof updateAvailable.failure
  | typeof dataUpdateAvailable.request
  | typeof dataUpdateAvailable.success
  | typeof dataUpdateAvailable.failure
>;

export type ConfigurationState = Readonly<{
  featureFlags: { [key: string]: boolean } | null;
  updateAvailable: boolean;
  dataUpdateAvailable: boolean;
}>;

const initialState: ConfigurationState = {
  featureFlags: null,
  updateAvailable: false,
  dataUpdateAvailable: false,
};

const reducer = (state: ConfigurationState = initialState, action: AnyAction) => {
  const typedAction = action as ConfigurationAction;
  switch (typedAction.type) {
    case getType(fetchFeatureFlags.success):
      return {
        ...state,
        featureFlags: typedAction.payload,
      };
    case getType(fetchFeatureFlags.failure):
      return {
        ...state,
        featureFlagError: typedAction.payload.errorMessage,
      };
    case getType(updateAvailable.success):
      return {
        ...state,
        updateAvailable: true,
      };
    case getType(updateAvailable.failure):
      return {
        ...state,
        updateAvailable: false,
      };
    case getType(dataUpdateAvailable.success):
      return {
        ...state,
        dataUpdateAvailable: true,
      };
    case getType(dataUpdateAvailable.failure):
      return {
        ...state,
        dataUpdateAvailable: false,
      };
    default:
      return state;
  }
};

export default reducer;
