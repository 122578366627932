import { push } from 'connected-react-router';
import ROUTES from 'constants/routes';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import appDB, { ICommissionRate, ICountry, ICurrency } from 'services/appDatabase';
import { logCurrency } from 'services/logging';
import { setGuestCurrency, setOwnerCurrency } from 'stateManagement/Currency';
import { ActionType, getType } from 'typesafe-actions';
import { useGuestPrice } from '.';
import quote, { disableReasonDialog, useGuestPriceCoefficients } from './actions';
import { getQuoteCommissionRates } from './selectors';

export function* quoteCreateSaga(action: ActionType<typeof quote.create>) {
  const currentCommissionRates: { commissionRate: number; minCommissionRate: number } =
    yield select(getQuoteCommissionRates);

  const currentCommissionRate = currentCommissionRates.commissionRate;
  if (currentCommissionRate === 0 || currentCommissionRate == null) {
    const defaultCommissionRate: ICommissionRate | undefined = yield appDB.commissionRate
      .toCollection()
      .first();
    if (!defaultCommissionRate) {
      throw new Error('No commissionRate found for this country');
    }
    yield put(quote.update({ commissionRate: defaultCommissionRate.value }));
  }

  const currentMinCommissionRate = currentCommissionRates.minCommissionRate;
  if (currentMinCommissionRate === 0 || currentMinCommissionRate == null) {
    const defaultMinCommissionRate: ICommissionRate | undefined = yield appDB.commissionRate
      .toCollection()
      .first();
    if (!defaultMinCommissionRate) {
      throw new Error('No min commissionRate found for this country');
    }
    yield put(quote.update({ minCommissionRate: defaultMinCommissionRate.minValue }));
  }

  const currentDefaultCommissionRate = currentCommissionRates.commissionRate;
  if (currentDefaultCommissionRate === 0 || currentDefaultCommissionRate == null) {
    const defaultCommissionRate: ICommissionRate | undefined = yield appDB.commissionRate
      .toCollection()
      .first();
    if (!defaultCommissionRate) {
      throw new Error('No min commissionRate found for this country');
    }
    yield put(quote.update({ defaultCommissionRate: defaultCommissionRate.value }));
  }

  yield put(push(ROUTES.CHARACTERISTICS));
}

function* initialiseQuoteSaga(): any {
  const countriesCollection = yield call([appDB.countries, appDB.countries.toCollection]);
  const country: ICountry = yield call([countriesCollection, countriesCollection.first]);
  const countryCurrency: ICurrency = yield call(
    // @ts-ignore-next-line
    [appDB.currencies, appDB.currencies.get],
    country.currency,
  );
  const guestCurrency: ICurrency = yield call(
    // @ts-ignore-next-line
    [appDB.currencies, appDB.currencies.get],
    country.guestCurrency,
  );

  yield put(setOwnerCurrency(countryCurrency));
  yield put(setGuestCurrency(guestCurrency));
  logCurrency(`$$$$$ Default country currency: ${countryCurrency.name}`, countryCurrency);
  logCurrency(`$$$$$ Default guest currency  : ${guestCurrency.name}`, guestCurrency);
  yield put(useGuestPrice(country.useGuestPrice));
  yield put(useGuestPriceCoefficients(country.useGuestPriceCoefficients));
  yield put(disableReasonDialog(country.disableReasonDialog));
}

export default function* quoteSagas() {
  yield takeEvery(getType(quote.create), quoteCreateSaga);
  yield takeEvery([getType(quote.create), getType(quote.loadQuote)], initialiseQuoteSaga);
}
