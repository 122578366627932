import { DeepPartial } from 'redux';
import { INewProperty, INewPropertyQuote, IOwnerWeek } from 'services/appDatabase';
import { EngineState, IAdjustment } from 'stateManagement/Engine';
import { createStandardAction } from 'typesafe-actions';

interface IUpdateQuote extends DeepPartial<INewProperty> {
  commissionRate?: number;
  minCommissionRate?: number;
  defaultCommissionRate?: number;
  individualSeasonPricing?: boolean;
  isResubmission?: boolean;
}

export const create = createStandardAction('QUOTE/CREATE')<INewProperty>();
export const update = createStandardAction('QUOTE/UPDATE_FEATURES')<IUpdateQuote>();
export const reset = createStandardAction('QUOTE/RESET')<{}>();
export const saveOwnerWeeks = createStandardAction('QUOTE/SAVE_OWNER_WEEKS')<IOwnerWeek[]>();
export const loadQuote = createStandardAction('QUOTE/LOAD_QUOTE')<INewPropertyQuote>();
export const fetchAndLoadQuote = createStandardAction('QUOTE/FETCH_AND_LOAD_QUOTE')<string>();
export const loadQuoteEngine = createStandardAction('QUOTE/LOAD_QUOTE_ENGINE')<EngineState>();
export const useGuestPrice = createStandardAction('QUOTE/USE_GUEST_PRICE')<boolean>();
export const useGuestPriceCoefficients = createStandardAction(
  'QUOTE/USE_GUEST_PRICE_COEFFICIENTS',
)<boolean>();
export const disableReasonDialog = createStandardAction('QUOTE/DISABLE_REASON_DIALOG')<boolean>();
export const updateCommissionRate = createStandardAction('QUOTE/UPDATE_COMMISSION_RATE')<
  IAdjustment<number>
>();

export default {
  create,
  update,
  reset,
  saveOwnerWeeks,
  loadQuote,
  fetchAndLoadQuote,
  loadQuoteEngine,
  useGuestPrice,
  useGuestPriceCoefficients,
  updateCommissionRate,
  disableReasonDialog,
};
