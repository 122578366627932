import { createMuiTheme } from '@material-ui/core/styles';

/**
 * App spacing measurement convention
 * Use the getSpacing function below to compute padding and margin
 */
const SPACING_UNIT = 5;
const MEASUREMENT_UNIT = 'px';

/**
 * Do not use directly the colorPalette in your components
 * Create an entry in the colorUsage below instead
 */
export const colorPalette = {
  greyDark: '#333333',
  greyDarkLighter: '#5e5e5e',
  lighterGrey: '#e1e1e1',
  teal: '#2ea68d',
  lightTeal: '#2EA68D33',
  grey: '#8798ad',
  lightGrey: '#dee5fc',
  white: '#FFF',
  background: '#F0F0F0',
  amberLight: '#F5C157',
  blueLight: '#2485E2',
  eggShell: '#E0E7FD',
  orange: '#fcaf17',
  black: '#000',
  red: '#ff0000',
  darkGrey: '#333',
  shadow: '#64646419',
  paleBlue: '#f9faff',
  pastelRed: '#e0483d',
  pastelGreen: '#59c257',
  brightYellow: '#fedc30',
  darkBlue: '#042f55',
  darkRed: '#b61d22',
  headerLineDarkRed: '#ed1c24',
  pdfLightestGrey: '#00000080',
  pdfLightGrey: '#00000099',
  pdfDarkGrey: '#000000B3',
  pdfDarkestGrey: '#000000CC',
};

/**
 * Use this dictionnary in your components
 * Define a new key each time you use a colour if it's for a different use
 * Ex: fill, border-color, background-color, color ...
 */
export const colorUsage = {
  headerBackground: colorPalette.greyDark,
  primaryTextColor: colorPalette.greyDark,
  primaryLight: colorPalette.darkBlue,
  primary: colorPalette.darkBlue,
  primaryDark: colorPalette.darkBlue,
  secondary: colorPalette.white,
  black: colorPalette.black,
  darkText: colorPalette.darkGrey,
  boxShadow: colorPalette.shadow,
  headerBoxBorder: colorPalette.lighterGrey,
  headerBoxBackground: colorPalette.paleBlue,
};

export const fontFamily = {
  main: `'Open Sans', 'Roboto', 'Helvetica', 'Arial', sans-serif`,
};

export const fontSize = {
  XXLarge: '60px',
  large: '40px',
  medLarge: '18px',
  medium: '16px',
  smallMedium: '15px',
  small: '14px',
  XSmall: '13px',
  XXSmall: '11px',
  XXXSmall: '10px',
};

export const lineHeight = {
  large: '36px',
  medium: '24px',
};

export const getSpacing = (multiplier: number): string =>
  `${multiplier * SPACING_UNIT}${MEASUREMENT_UNIT}`;

export const getSpacingWithOffset = (multiplier: number, offset: number): string =>
  `${multiplier * SPACING_UNIT + offset}${MEASUREMENT_UNIT}`;

const INPUT_HEIGHT = '49px';
export const NAVBAR_HEIGHT = 64;
export const PANEL_WIDTH = '230px';
export const PANEL_PADDING_MULTIPLIER = 3;
export const PANEL_PADDING_TOP = getSpacingWithOffset(PANEL_PADDING_MULTIPLIER, NAVBAR_HEIGHT);
export const QUOTE_MAX_WIDTH = 600;
export const QUOTE_MAX_WIDTH_WIDE = 800;
export const TABLE_MAX_WIDTH = 700;
export const REVENUE_GRAPH_HEIGHT = '175px';
export const getHeightWidthFromPanel = (multiplier: number): number => QUOTE_MAX_WIDTH / multiplier;

export const MENU_WIDTH = '280px';

export const isMobile = window.matchMedia('(min-width: 450px)').matches;

export const COTTAGES_LOGO_DIMENSIONS = {
  big: {
    width: 300,
    height: 54,
  },
  small: {
    width: 160,
    height: 30,
  },
};

export const NOVASOL_LOGO_DIMENSIONS = {
  big: {
    width: 150,
    height: 150,
  },
  small: {
    width: 53.86,
    height: 54.32,
  },
};

// Material UI - theme, do not declare constant inside this object
export const muiTheme = createMuiTheme({
  typography: {
    fontFamily: fontFamily.main,
    h2: {
      fontSize: fontSize.medLarge,
      color: colorPalette.greyDark,
    },
    h3: {
      color: colorPalette.greyDark,
      fontSize: fontSize.medLarge,
    },
    h4: {
      fontSize: fontSize.small,
      fontWeight: 'bold',
      color: colorPalette.greyDark,
    },
    h5: {
      color: colorPalette.greyDark,
      fontSize: fontSize.XSmall,
    },
    h6: {
      color: colorPalette.grey,
      fontSize: fontSize.XXXSmall,
    },
    subtitle1: {
      fontSize: fontSize.medLarge,
    },
    subtitle2: {
      fontSize: fontSize.XXSmall,
    },
    body1: {
      fontSize: fontSize.XSmall,
    },
    caption: {
      fontSize: fontSize.XSmall,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        fontSize: fontSize.small,
        height: getSpacing(10),
        marginTop: getSpacing(4),
        borderRadius: getSpacing(0.5),
        textTransform: 'none',
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: fontSize.large,
      },
    },
    MuiFormControl: {
      marginNormal: {
        marginBottom: getSpacing(4),
        marginTop: getSpacing(4),
      },
      marginDense: {
        marginBottom: getSpacing(2),
        marginTop: getSpacing(2),
      },
    },
    MuiInputBase: {
      root: {
        height: INPUT_HEIGHT,
      },
      multiline: {
        height: 'auto', // this is to override the INPUT_HEIGHT used above on the root element
      },
    },
    MuiInput: {
      root: {
        border: `1px solid ${colorPalette.lightGrey}`,
        borderRadius: getSpacing(1),
      },
      underline: {
        borderBottom: `1px solid ${colorPalette.lightGrey}`,
        '&:before': {
          borderBottom: 'none',
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottom: 'none',
        },
      },
    },
    MuiTypography: {
      gutterBottom: {
        marginBottom: getSpacing(2),
      },
    },
    MuiIconButton: {
      root: {
        padding: 0,
      },
    },
    // MuiModal: {
    //   root: {
    //     zIndex: 1,
    //   },
    // },
  },
  palette: {
    primary: {
      light: colorUsage.primaryLight,
      main: colorUsage.primary,
      dark: colorUsage.primaryDark,
    },
    secondary: {
      light: colorUsage.secondary,
      main: colorUsage.secondary,
      dark: colorUsage.secondary,
    },
    text: {
      primary: colorUsage.primaryTextColor,
      secondary: colorPalette.grey,
    },
  },
});
