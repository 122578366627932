import { ICurrency } from 'services/appDatabase';
import { RootState } from 'stateManagement/types';
import { CurrencyState } from './reducer';

export const getCurrencies = (state: RootState): CurrencyState => state.currency;
export const getOwnerCurrency = (state: RootState): ICurrency | null =>
  state.currency.ownerCurrency;
export const getEngineCurrency = (state: RootState): ICurrency | null =>
  state.currency.engineCurrency;
export const getGuestCurrency = (state: RootState): ICurrency | null =>
  state.currency.guestCurrency;
export const getEuroCurrency = (state: RootState): ICurrency | null => state.currency.euroCurrency;
